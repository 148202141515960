﻿.locations-index{
    .o-breadcrumbs{
        margin-bottom: 16px;
    }
}

.locations-map {
    > .grid-x {
        height: 100%;
    }

    &--map {
        > .grid-x {
            @media #{$mobile} {
                min-height: 800px;
            }
        }
    }
}

.toggle-controls {
    background-color: $brand;

    &__control {
        width: 100%;
        cursor: pointer;
        margin: 0;
    }
}
.map-controls {
    padding-top: pxToResponsiveUnit(10px);
    padding-bottom: pxToResponsiveUnit(20px);
    background-color: $brand;
    color: $white;

    &__container {
        // Align with breadcrumbs
        @include xy-grid-container($grid-container + (45 / 16), 0);
    }

    &__back {
        background-color: $linkColor;

        a, i {
            color: $white;
            text-transform: uppercase;
        }
    }

    &__label {
        color: inherit;
        font-size: 1.125rem;
        margin: 0;
        text-transform: uppercase;
    }

    &__control {
        width: 100%;
        height: pxToResponsiveUnit(48px);
        margin: 0;

        input, button {
            height: 100%;
            margin: 0;
        }

        select {
            padding-right: pxToResponsiveUnit(48px);
        }
    }

    .location-field {
        position: relative;

        .fa {
            color: $carleRed;
            font-size: 1.5rem;
            position: absolute;
            top: 50%;
            left: 1rem;
            transform: translateY(-50%);
        }

        input {
            padding-left: 3rem;
        }
    }
}

.feature-list {
    @include xy-grid();
    list-style: none;
    font-size: 1rem;

    &:focus {
        outline: 4px solid $focusColor;
        outline-offset: 4px;
    }

    &__feature {
        // 64 = 32px + 32px = (2rem + 2rem)
        @include xy-cell($gutters: 64, $gutter-type: padding);
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid rgba(85,85,89,0.5);

        @include breakpoint(small) {
            @include xy-cell($gutters: 32, $gutter-type: padding);
        }

        &--no-results {
            border-bottom: 0 none;
            padding-left: 2.3rem;
        }

        .inner {

            &__photo {
                width: 76px;
                height: 76px;
                background: transparent no-repeat center / cover scroll;

                &--default {
                    background-size: 76px 76px;
                }

                img {
                    visibility: hidden;
                }
            }

            &__description {
                .body {
                    min-height: 216px;

                    &__location .location {
                        .title {
                            @include featureTitle;
                        }

                        .address {
                            @include featureAddress;
                        }

                        &__distance {
                            font-weight: 900;
                        }
                    }
                }

                .ctas {
                    &__cta {
                        &--directions {
                            text-align: right;

                            a {
                                @include carleButton;
                                padding: pxToResponsiveUnit(16px) pxToResponsiveUnit(22px);
                                border-color: $carleRed;
                                background-color: transparent;
                                color: $carleRed;
                                text-transform: uppercase;

                                &:focus {
                                    outline: 4px solid $focusColor;
                                    outline-offset: 4px;
                                }
                            }

                            @include breakpoint(small only) {
                                text-align: left;

                                a {
                                    padding: 0;
                                    border: 0 none;
                                    color: $linkColor;
                                    text-transform: none;
                                    font-size: 1rem;
                                }
                            }
                        }

                        &--phone {
                            a {
                                display: inline-block;
                                width: auto;
                                margin: 0;
                                padding: pxToResponsiveUnit(8px) pxToResponsiveUnit(16px);
                            }
                        }
                    }
                }
            }
        }

        .a-app {
            max-width: 600px;
            margin: 0 auto;
        }
    }
}

.popup {
    &__inner {
        &--mobile {
            min-width: 250px;
            padding-top: pxToResponsiveUnit(10px);
        }
    }

    &__navigation {
        width: 85%;
        padding-bottom: pxToResponsiveUnit(5px);
        border-bottom: 1px solid $carleMediumGrey;
        position: absolute;
        top: 5px;
        left: 16px;
    }

    &__body {
        .a-btn-inverse {
            width: 100%;
            text-align: center;
        }

        .a-app {
            box-shadow: none;
            background: transparent;

            &__actions {
                text-align: center;
            }
        }
    }
}

.location-tag {
    display: inline-block;
    height: pxToResponsiveUnit(20px);
    padding-left: pxToResponsiveUnit(12px);
    padding-right: pxToResponsiveUnit(12px);
    font-size: pxToResponsiveUnit(14px);
    margin-bottom: 1rem;

    @include tag;

    &--inpatient, &--hospital {
        background: #900000;
    }

    p {
        height: 100%;
        margin: 0;
    }
}

#locations-campus-maps {
    padding-top: pxToResponsiveUnit(35px);
    padding-left: pxToResponsiveUnit(88px);
    border-top: 1px solid #555559;

    @include breakpoint(small only) {
        padding-left: 0;
    }

    .h3, h3 {
        font-size: 2rem;
    }
}