﻿.for-providers-careers-search-details,
.for-providers-clinical-trials-details {
    p.h3 {
        font-size: 2rem;
        margin-top: 32px;
        margin-bottom: 32px;

        @include breakpoint(small only) {
            margin-top: 12px;
            margin-bottom: 24px;
        }
    }

    .a-btn-main {
        text-align: center;
        width: 176px;

        @include breakpoint(small only) {
            width: 100%;
        }
    }

    .grid-container.divider {
        padding-left: 0;
        padding-right: 0;
    }

    .go-back {
        display: block;
        margin-top: 20px;

        @include breakpoint(medium down) {
            margin-top: 8px;
        }
        @include breakpoint(small only){
            margin-bottom: 16px;
        }
    }

    .m-details-subhead {
        &__title {
            p.h3 {
                margin-top: 0;
                margin-bottom: 24px;
            }
        }

        &__details {
            margin-bottom: 24px;

            > .cell {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }

    .m-details-body {
        padding-top: 24px;
    }

    .contact-us {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

.equal-opportunity {
    background-color: $carleLightGrey;
    margin-top: 40px;
    margin-bottom: 40px;

    .grid-x{
        padding: 9px;
    }
    p {
        margin: 0;
    }
}

#for-providers-careers-form {
    label[for=Resume_File] {
        @extend .a-btn-inverse;
        float: left;
        margin-right: 10px;
        content: 'Upload Resume';

        + .ktc-file-component-wrapper {
            display: none;
        }
    }

    button[type=submit] {
        float: left;
    }

    .form-display-text {
        @extend .h3;
    }
}

#for-providers-trials-form {
    .ktc-default-section {
        > div {
            width: 50%;
            float: left;
            padding-right: 20px;

            @include breakpoint(medium down) {
                width: 100%;
                float: none;
                padding-right: 0;
            }
        }
    }

    .form-display-text {
        @extend .h3;
    }

    button[type=submit] {
        display: block;
        clear: both;
    }
}

.residencies-provider-card {
    @include elevation();
    padding: 25px;
    margin-bottom: 30px;

    @media #{$mobile} {
        text-align: center;
    }

    &__name {
        border-right: 1px solid $carleMediumGrey;

        img {
            max-width: 100%;
        }

        .fullname {
            font-size: 1.5rem;
            font-weight: 900;

            @media #{$mobile} {
                padding-top: 1rem;
            }
        }

        @media #{$mobile} {
            border-right: 0;
        }
    }

    &__location {
        min-height: 165px;

        @media #{$mobile} {
            min-height: 105px;
        }
    }
}
