/* ------------------------------------------------------------ *\
	Contact-card
\* ------------------------------------------------------------ */

.contact-card {
	background-color: #fff;
	box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.1);
	padding: 23px;
	margin-bottom: 32px;

	display: flex;

	@media #{$mobile} {
		flex-wrap: wrap;
	}

	@media #{$mobile-small} {
		padding: 15px;
		margin-bottom: 20px;
	}

	p {
		font-family: 'Avenir LT Std', sans-serif;
		font-weight: 300;
		font-size: 16px;
		line-height: 1.25;
		letter-spacing: 0;
		color: #555559;
	}

	ul {
		list-style: none;
		margin: 0;

		@media #{$mobile} {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	li {
		margin-bottom: 25px;
		font-family: 'Avenir', sans-serif;
		font-weight: 900;
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: 0;
		color: #555559;

		@media #{$mobile} {
			margin-bottom: 15px;
			padding: 0 10px;
		}
	}

	a {
		color: #037d96;
		font-weight: 600;
	}

	.contact-card__content {
		max-width: 53.3%;
		flex: 0 0 53.3%;
		padding: 0 24px;

		@media #{$mobile} {
			max-width: 100%;
			flex: 1;
		}
	}

	.contact-card__image {
		max-width: 56px;
		flex: 0 0 56px;
		width: 56px;
		height: 56px;

		img {
			width: 100%;
			margin: 0;
		}
	}

	.contact-card__aside {
		max-width: 100%;
		flex: 1;
		border-left: solid 1px #d8d8d8;
		padding: 8px 0 0 38px;

		@media #{$mobile} {
			border-left: none;
			border-top: solid 1px #d8d8d8;
			flex: 0 0 100%;
			padding: 20px 0 0 0;
		}
	}

	.contact-card__title {
		margin-bottom: 18px;
		font-family: 'Avenir', sans-serif;
		font-weight: 900;
		font-size: 24px;
		line-height: 1.17;
		letter-spacing: 0;
		color: #555559;
	}
}
