textarea {
  border: 1px solid #555559;
  height: 150px; }

.success-msg {
  display: none; }

.a-input-error {
  color: #C32032; }

[type=checkbox] + label, [type=radio] + label {
  text-transform: none; }
