






ul.social-icons {
    list-style: none;
    margin: 0;

    li {
        display: inline-block;
        font-size: 2rem;
        margin-right: 4px;
    }
}
