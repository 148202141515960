﻿@import '_variables.scss';

// Nav

.m-nav {
    display: block;
    margin: 0 0 0 41px;
    text-align: right;
    font-size: 0.9375rem;
    line-height: 1.33;
    font-family: $font-family-avenir;
    font-weight: 900;

    ul {
        display: flex;
        margin: 0;
        justify-content: flex-end;
        list-style-type: none;
    }

    li {
        padding: 4px 16px 3px;

        & ~ li {
            margin-left: 0px;
        }

        &:hover,
        &.hover {
            background-color: $linkColor;

            @media #{$mobile} {
                background-color: unset;
            }
        }

        &.nav-my-carle-link {
            display: none;
        }
    }

    a {
        color: $white;
        transition: color 0.3s ease;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &.js-clone {
        display: none;
    }

    .has-dd {
        position: relative;

        & > a {
            display: inline-block;
            position: relative;

            &:after {
                content: '';
                width: 0;
                height: 0;
                position: absolute;
                right: -10px;
                top: 50%;
                transform: translateY(-50%);
                border-style: solid;
                border-width: 4px 3px 0 3px;
                border-color: #ffffff transparent transparent transparent;
                transition: transform 0.3s ease;
            }
        }

        ul {
            position: absolute;
            display: block;
            top: 100%;
            left: 0;
            min-width: 272px;
            padding: 24px 0;
            background-color: $linkColor;
            color: $white;
            text-align: left;
            z-index: 1;
            font-family: $font-family-avenir;
            font-size: 15px;
            line-height: 1.33;
            font-weight: 400;
            opacity: 0;
            transform: translateY(8px);
            transition: all 0.3s ease;
            pointer-events: none;
        }

        li {
            padding: 0;

            & ~ li {
                margin: 21px 0 0;
            }

            a {
                display: inline-block;
                padding: 0 12px;
            }
        }

        @media (min-width: 1024px) {
            & > a {
                &:focus {
                    &:after {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }

            &:hover,
            &.hover,
            &:focus {
                & > a {
                    &:after {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }

                ul {
                    transform: translateY(0px);
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }

    @media #{$small-desktop} {
        li {
            padding: 4px 9px 3px;
        }

        font-size: 0.8rem;
    }

    @media #{$mobile} {
        display: none;
        margin: 0;
        padding: 20px 10px;
        text-align: left;
        font-weight: 700;
        border-bottom: 1px solid $grey-100;

        a {
            color: $linkColor;
        }

        ul {
            display: block;
            max-width: 320px;
            column-count: 2;
        }

        li {
            padding: 0;

            & ~ li {
                margin-left: 0;
                margin-top: 10px;
            }

            &.nav-my-carle-link {
                display: block;
            }
        }

        &.js-clone {
            display: block;
        }

        .has-dd {
            position: relative;

            & > a {
                display: inline-block;
                position: relative;
            }

            ul {
                position: relative;
                display: none;
                top: auto;
                left: auto;
                min-width: unset;
                padding: 12px 5px;
                background-color: $white;
                color: $linkColor;
                text-align: left;
                font-size: 13px;
                transform: translateY(0);
                pointer-events: all;
                opacity: 1;
                transition: none;
            }

            li {
                & ~ li {
                    margin: 7px 0 0;
                }

                a {
                    display: inline-block;
                    padding: 0 0px;
                }
            }
        }
    }
}

// Nav Alt

.m-nav-alt {
    margin: 0 0 0 0px;
    font-family: $font-family-avenir;
    font-size: 0.875rem;
    line-height: 1.36;
    font-weight: 900;

    ul {
        display: flex;
        list-style-type: none;
        margin: 0;
    }

    li {
        &.m-healthalliance-mobile {
            display: none;
        }

        &.m-healthalliance-desktop {
            display: block;
        }

        & ~ li {
            //margin-left: 24px;
        }
    }

    a {
        color: $white;
        transition: color 0.3s ease;

        &:hover {
            text-decoration: underline;
        }
    }

    &.js-clone {
        display: none;
    }

    &.js-clone-alt {
        display: none;
    }

    @media #{$small-desktop} {
        font-size: 0.7rem;
    }

    @media #{$mobile} {
        display: none;
        padding: 20px 10px;
        text-align: left;
        border-bottom: 1px solid $grey-100;

        a {
            color: $maintextcolor;
        }

        ul {
            display: block;
        }

        li {
            display: none;

            & ~ li {
                display: block;
                margin-left: 0;
                margin-top: 0;
            }
        }

        a {
            color: $maintextcolor;

            span {
                color: $linkColor;
            }
        }

        &.js-clone {
            display: block;

            li {
                display: block;
                // & ~ li {
                //     display: none;
                //     margin-left: 0;
                //     margin-top: 0;
                // }
                &.m-healthalliance-mobile {
                    display: none;
                }

                &.m-healthalliance-desktop {
                    display: none;
                }
            }
        }

        &.js-clone-alt {
            display: block;
            border-bottom: none;

            li {
                display: block;

                & ~ li {
                    display: none;
                    margin-left: 0;
                    margin-top: 0;
                }

                &.m-healthalliance-mobile {
                    display: block;
                }

                &.m-healthalliance-desktop {
                    display: none;
                }
            }

            a {
                color: $linkColor;
            }
        }
    }
}

// Nav Secondary

.m-nav-secondary {
    margin: 0 58px 0 16px;
    font-size: 1rem;
    color: $maintextcolor;
    font-weight: 900;

    ul {
        display: flex;
        margin: 0;
        list-style-type: none;
    }

    li {
        display: block;

        & ~ li {
            margin-left: 36px;
        }

        &.open {
            .m-dropdown {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }

            & > h2 button {
                background-color: $carleLightGrey;

                &:focus {
                    background-color: $focusColor;
                }
            }
        }
    }

    h2 {
        //needed for accesibility
        font-size: inherit;
        padding: 0;
        margin: 0;
        font-family: inherit;
    }

    a,
    button {
        padding: 35px 10px;
        display: inline-block;
        position: relative;
        font-weight: 900;
        color: $maintextcolor;
        cursor: pointer;

        span {
            font-size: 10px;
            color: $carleRed;
            text-transform: uppercase;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 25px;
            height: 3px;
            background-color: $carleRed;
            transform: scaleX(0);
            //transition: transform .1s ease;
            transform-origin: right;
        }

        &:hover {
            text-decoration: none;

            &:after {
                transform: scaleX(1);
                transform-origin: left;
            }
        }
    }

    &.js-clone {
        display: none;
    }

    @media #{$large-desktop} {
        margin: 0 20px;
    }

    @media #{$small-desktop} {
        font-size: 0.9rem;

        li {
            & ~ li {
                margin-left: 15px;
            }
        }
    }

    @media #{$mobile} {
        font-size: 1rem;
        display: none;
        margin: 0 0 0px 0;
        text-align: center;
        border-top: 2px solid $grey-100;

        button {
            width: 100%;
            line-height: inherit;
        }

        a,
        button {
            display: block;
            padding: 15px 65px 15px 10px;
            text-align: left;
            color: $linkColor;
            border-bottom: 1px solid $grey-100;

            &:after {
                display: none;
            }

            &:focus {
                background-color: inherit;
            }
        }

        ul {
            display: block;
        }

        li {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 25px;
                right: 20px;
                width: 20px;
                height: 2px;
                transform: translateY(-50%);
                background-color: black;
                pointer-events: none;
            }

            &:after {
                content: '';
                position: absolute;
                top: 25px;
                right: 29px;
                width: 2px;
                height: 20px;
                transform: translateY(-50%);
                background-color: black;
                pointer-events: none;
                transition: transform 0.3s ease;
            }

            & ~ li {
                margin-left: 0;
            }

            &.open {
                &:before {
                    display: none;
                }

                &:after {
                    transform: translateY(-50%) rotate(90deg);
                }

                & > a {
                    background-color: transparent;
                }

                & > h2 button {
                    &:focus {
                        background-color: inherit;
                    }
                }
            }
        }

        &.js-clone {
            display: block;
        }
    }
}

.has-dd--alt {
    position: relative;
}

.o-triggers {
    display: none;
    align-items: center;
    @media #{$mobile} {
        display: flex;
    }
}

.search-trigger {
    display: none;

    @media #{$mobile} {
        display: inline-block;
    }
}

.nav-trigger {
    position: relative;
    display: none;
    width: 16px;
    height: 16px;
    margin-left: 17px;

    span {
        position: absolute;
        display: block;
        height: 2px;
        width: 100%;
        border-radius: 4px;
        background: $carleRed;
        transition: top 0.2s 0.25s, opacity 0.2s 0.25s, transform 0.2s 0s;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: 7px;
        }

        &:nth-child(3) {
            top: 14px;
        }
    }

    &--active {
        span {
            transition: background 0.2s, top 0.2s, opacity 0.2s,
                transform 0.2s 0.25s;

            &:nth-child(3),
            &:nth-child(1) {
                top: 7px;
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(1) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }

    @media (max-width: 1024px) {
        display: inline-block;
    }
}

// Dropdown

.m-dropdown {
    text-align: center;
    position: absolute;
    left: -111px;
    right: -111px;
    top: 100%;
    padding: 24px 0;
    background-color: $carleLightGrey;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;

    ul {
        display: block;

        li {
            & ~ li {
                margin: 3px 0 0;
            }
        }
    }

    .a-dropdown__inner {
        position: relative;
        display: inline-block;
        width: auto;
        padding: 0 0 0 391px;
    }

    .a-dropdown__entry {
        position: absolute;
        left: 128px;
        top: 5px;
        width: 233px;
        display: inline-block;
    }

    .a-dropdown__image {
        padding: 0 0 100%;
        position: relative;
        overflow: hidden;

        a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-size: cover;
            background-position: center center;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.1);
            }

            &:after {
                display: none;
            }
        }
    }

    .a-dropdown__icon {
        position: relative;
        display: inline-block;
        width: 48px;
        height: 48px;

        a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-size: cover;
            background-position: center center;
            transition: opacity 0.3s ease;

            &:hover {
                opacity: 0.7;
            }

            &:after {
                display: none;
            }
        }
    }

    .a-btn-main {
        padding: 10px 46px !important;
        color: $white;
        font-size: 0.875rem;

        &:after {
            display: none;
        }

        &--tight {
            padding: 12px 10px !important;
            font-size: 0.875rem;
            width: 100%;
            text-align: center;
        }
    }

    .a-btn-inverse {
        width: 100%;
        padding: 12px 10px;
        text-align: center;
        font-size: 0.875rem;
        color: $carleRed;
        text-transform: uppercase;

        &:after {
            display: none;
        }

        &:hover {
            color: $carleButtonHoverColor;
        }
    }

    .a-dropdown__actions {
        padding: 16px 25px;
    }

    .m-dropdown__content {
        display: inline-block;
        min-width: 826px;
        padding: 7px 79px 0 30px;
        margin: 0 0 0 0px;
        border-left: 2px solid $grey-100;
        text-align: left;

        .grid-x {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        .a-dropdown__image {
            display: inline-block;
            height: 57px;
            width: 57px;
            padding: 0;
        }
    }

    .m-dropdown__links {
        margin: 0 20px 31px 0;

        a {
            padding: 0;
            color: $linkColor;
            font-size: 0.875rem;

            &:hover {
                color: $carleDarkBlue;
                text-decoration: underline;

                &:after {
                    background-color: transparent;
                }
            }

            &:after {
                height: 2px;
                bottom: 0;
            }
        }

        h2,
        h3 {
            margin: 0 0 11px;
            padding: 0 0 4px;
            font-size: 1rem;
            font-weight: 700;
            border-bottom: 1px solid $grey-100;

            a {
                color: $linkColor;
                font-size: 1em;
            }
        }

        ul {
            line-height: 1.25rem;
        }

        &--cols {
            ul {
                columns: 1;
            }
        }
    }

    &--alt {
        padding: 23px 32px;
        left: -204px;
        min-width: 607px;
        right: auto;

        h4 {
            display: inline-block;
            width: 83.33%;
            padding: 0 0 5px;
            margin: 0 0 10px;
            border-bottom: 1px solid $grey-100;
            color: $linkColor;
            font-family: $font-family-avenir;
            font-size: 1.125rem;
            font-weight: 700;
        }

        .a-dropdown__entry {
            position: relative;
            left: auto;
            top: auto;
            width: auto;
            flex: 0 0 49%;
            max-width: 49%;
            display: inline-block;
            padding: 0 34px 0 0;
        }

        .a-dropdown__inner {
            display: flex;
            //flex-direction: row-reverse;
            justify-content: flex-end;
            padding: 0 0 0 0px;
        }

        .m-dropdown__content {
            min-width: auto;
            flex: 0 0 51%;
            max-width: 51%;
            padding: 3px 29px;
            border: 0;
            border-left: 1px solid $grey-100;
        }

        .m-dropdown__links {
            margin: 0 20px 0px 0;
        }

        .a-dropdown__actions {
            padding: 16px 0px !important;

            .a-btn-main {
                width: 100%;
                padding: 12px 10px;
            }
        }

        ul {
            &.cols-2 {
                columns: 2 !important;
            }
        }
    }

    &--large {
        min-width: 766px;
        left: -340px;

        .cols {
            display: flex;
            margin: 0 -10px;
        }

        .col {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 10px;
        }

        .m-dropdown__links {
            margin-right: 0;
        }

        .m-dropdown__content {
            padding: 3px 10px 3px 29px;
            flex: 0 0 65%;
            max-width: 65%;
        }

        .a-dropdown__entry {
            flex: 0 0 35%;
            max-width: 35%;
        }
    }

    @media (max-width: 1450px) {
        left: 0px;
        right: 0px;

        .m-dropdown__links {
            h2,
            h3 {
                font-size: 0.875rem;
            }
        }

        .m-dropdown__content {
            min-width: 500px;
        }

        .a-dropdown__inner {
            position: relative;
            display: inline-block;
            width: auto;
            padding: 0 0 0 255px;
        }

        .a-dropdown__entry {
            position: absolute;
            left: 20px;
            top: 5px;
            width: 200px;
            display: inline-block;
        }

        .a-dropdown__actions {
            padding: 16px 0;
        }

        &--alt {
            padding: 23px 32px;
            left: -204px;
            width: 607px;
            right: auto;

            h4 {
                display: inline-block;
                width: 83.33%;
                padding: 0 0 5px;
                margin: 0 0 10px;
                border-bottom: 1px solid $grey-100;
                color: $linkColor;
                font-family: $font-family-avenir;
                font-size: 14px;
                font-weight: 700;
            }

            .a-dropdown__entry {
                position: relative;
                left: auto;
                top: auto;
                width: auto;
                flex: 0 0 49%;
                max-width: 49%;
                display: inline-block;
                padding: 0 34px 0 0;
            }

            .a-dropdown__inner {
                display: flex;
                // flex-direction: row-reverse;
                justify-content: flex-end;
                padding: 0 0 0 0px;
            }

            .m-dropdown__content {
                min-width: auto;
                flex: 0 0 51%;
                max-width: 51%;
                padding: 3px 29px;
                border: 0;
                border-left: 1px solid $grey-100;
            }

            .a-dropdown__actions {
                padding: 16px 0px !important;

                .a-btn-main {
                    width: 100%;
                }
            }
        }

        &--large {
            min-width: 766px;
            left: -340px;

            .m-dropdown__content {
                flex: 0 0 62%;
                max-width: 62%;
            }

            .a-dropdown__entry {
                flex: 0 0 38%;
                max-width: 38%;
            }
        }
    }

    @media #{$mobile} {
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        display: none;
        padding: 10px 0;
        border: 0;
        border-bottom: 1px solid $grey-100;
        text-align: left;
        background-color: $grey;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);

        ul {
            display: block;
            flex: 1;
            padding: 0 10px;

            li {
                display: block;

                &:after,
                &:before {
                    display: none;
                }

                & ~ li {
                    display: none;
                }
            }
        }

        .a-dropdown__inner {
            display: block;
            padding: 0;
        }

        .a-dropdown__entry {
            position: relative;
            max-width: 100%;
            width: 100%;
            display: block;
            left: auto;
            top: auto;

            .a-dropdown__image {
                display: none;
            }

            .a-dropdown__actions {
                padding: 10px 20px 0;
                text-align: center;
            }

            .a-btn-inverse {
                display: inline-block;
                width: auto;
                border: 2px solid $carleRed;

                &:before {
                    display: none;
                }
            }
        }

        .m-dropdown__content {
            padding: 0 20px;
            border: 0;
            display: block;
            max-width: 100%;
            min-width: 100%;

            .a-dropdown__image {
                width: 45px;
                height: 45px;

                a {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        .m-dropdown__links {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0;
            border-bottom: 1px solid $grey-100;

            h2,
            h3 {
                margin: 0 0 0px;
                padding: 0 0 0px;
                font-size: 1rem;
                font-weight: 700;
                border-bottom: 0;

                a {
                    color: $linkColor;
                    font-size: 1em;
                }
            }

            a {
                display: block;
                padding: 20px 0;
                border: 0;
                color: $linkColor !important;
                font-weight: 700;

                &:after,
                &:before {
                    display: none;
                }
            }
        }

        &--alt {
            padding: 10px 0 0;
            width: 100%;
            min-width: initial;

            h4 {
                width: 100%;
                margin: 0 10px 10px;
                font-size: 0.9375rem;
                border-bottom: 0;
            }

            h2.small-dropdown {
                width: 100%;
                margin: 0 10px 10px;
                font-size: 1.125rem;
                border-bottom: 0;
            }

            ul {
                display: block;
                padding: 0 10px;

                li {
                    display: block;

                    & ~ li {
                        display: block;
                        margin-top: 10px;
                    }
                }
            }

            .m-dropdown__links {
                border: 0;
                margin: 0 0 10px;

                .a-dropdown__image {
                    display: none;
                }

                a {
                    padding: 0;
                }
            }

            .m-dropdown__content {
                padding: 10px 0;
            }

            .a-dropdown__entry {
                padding: 0 20px;
                background-color: $white;

                .a-dropdown__actions {
                    padding: 10px 30px;
                    border-top: 1px solid $grey-100;
                }
            }

            .a-btn-main {
                text-align: center;

                &:before,
                &:after {
                    display: none;
                }
            }

            .a-btn-inverse {
                width: 100% !important;
                margin-top: 10px;
            }
        }

        &--large {
            min-width: 100%;
            left: 0px;

            .cols {
                display: block;
                margin: 0;
                .col--1of2 ul {
                    margin-top: 10px;
                }
            }

            .col {
                max-width: 100%;
            }
        }

        &--small {
            padding: 10px 0 10px;

            h2.small-dropdown {
                width: 100%;
                margin: 0 10px 10px;
                font-size: 0.9375rem;
                border-bottom: 0;
            }

            ul {
                display: block;
                padding: 0 10px;
                columns: 1 !important;

                li {
                    display: block;

                    & ~ li {
                        display: block;
                        margin-top: 10px;
                    }
                }
            }

            .m-dropdown__links {
                border: 0;
                margin: 0 0 20px;

                .a-dropdown__image {
                    display: none;
                }

                a {
                    padding: 0;
                }
            }

            .m-dropdown__content {
                padding: 10px 0;

                .a-dropdown__actions {
                    display: block;
                    padding: 20px 30px 0;
                    border-top: 1px solid $grey-100;
                }

                .a-btn-main {
                    margin: 0 0 10px;

                    &:hover {
                        color: $carleRed;
                    }
                }

                .a-btn-main,
                .a-btn-inverse {
                    width: 100%;
                    text-align: center;
                    border: 2px solid $carleRed;

                    &:before {
                        display: none;
                    }
                }

                .a-btn-inverse {
                    border: 2px solid $carleRed;
                }
            }

            .a-dropdown__entry {
                padding: 0 20px;

                .a-dropdown__actions {
                    display: block;
                    padding: 10px 30px 0;
                    border-top: 1px solid $grey-100;
                }
            }
        }
    }
}

.nav-user-chpp {
    ul {
        display: flex;
        margin-bottom: 0;
        list-style-type: none;
    }

    a {
        color: #fff;
        font-size: 15px;
        font-weight: 700;
        color: #fff !important;

        @media (max-width: 1024px) {
            font-size: 14px;
        }
    }

    li {
        @media (max-width: 1024px) {
            display: none;
        }
    }

    li ~ li {
        position: relative;
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid #fff;

        @media (max-width: 1024px) {
            display: block;
            border-left: 0;
        }
    }
}

.m-nav-chpp {
    @media (min-width: 1024px) {
        // display:none; //disabling on desktop until further items are built
    }



    @media (max-width: 1024px) {
        margin-top: 25px;
        border-top: 1px solid #555559;
    }

    ul {
        display: flex;
        margin: 0;
        list-style-type: none;
        justify-content: center;
        font-size: 14px;

        @media (max-width: 1024px) {
            display: block;
            text-align:center;
        }
    }

    li {
        margin: 0 21px;

        @media #{$large-desktop} {
            margin: 0 10px;
        }

        @media (max-width: 1024px) {
            margin: 0 0px;
            border-bottom: 1px solid #555559;
        }
    }

    li:hover a {
        background-color: #f9f9f9;

        @media (max-width: 1024px) {
            background-color: #fff;
        }
    }

    > ul > li > a {
        position: relative;
    }

    > ul > li > a:after {
        content: '';
        width: 90%;
        height: 4px;
        background-color: #c32032;
        display: block;
        position: absolute;
        bottom: 10px;
        left: 5%;
        z-index: 10;
        transform: scaleX(0);
        transform-origin: center;
        transition: transform 0.2s;
    }

    @media (min-width: 1025px) {
        > ul > li > a:hover {
            text-decoration: none;

            &:after {
                transform: scaleX(1);
            }
        }
    }

    a {
        display: block;
        padding: 13px 13px 13px;
        font-weight: 600;
        transition: background-color 0.3s ease;

        @media (max-width: 1024px) {
            padding: 15px 13px 15px;
        }
    }

    li ul {
        position: absolute;
        top: 96%;
        display: block;
        text-align: center;
        background-color: #f9f9f9;
        z-index: 5;
        transition: background-color 0.3s ease, transform 0.3s ease;
        opacity: 0;
        transform: translateY(-10px);

        @media (max-width: 1024px) {
            position: relative;
            pointer-events: all;
            top: auto;
            transform: translateY(0px);
            text-align: left;
            opacity: 1;
            background-color: #fff;
            display: none;
        }
    }

    li a:focus + ul {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0px);
        transition: background-color 0.3s ease, transform 0.3s ease;
    }

    li:hover ul {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0px);
        transition: background-color 0.3s ease, transform 0.3s ease;
    }

    li ul li {
        margin: 0 10px;

        @media (max-width: 1024px) {
            border: 0 !important;
        }
    }

    li ul a {
        padding: 13px 5px 13px;
    }

    li.is-open ul {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0px);
        transition: background-color 0.3s ease, transform 0.3s ease;
    }

    li.has-dd {
        @media (max-width: 1024px) {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                right: 10px;
                top: 25px;
                width: 12px;
                height: 2px;
                transform: translateY(-50%);
                background-color: #555559;
            }

            &:after {
                content: '';
                position: absolute;
                right: 15px;
                top: 25px;
                width: 2px;
                height: 12px;
                transform: translateY(-50%);
                background-color: #555559;
                transition: opacity 0.3s ease;
            }
        }
    }

    li.active {
        &:after {
            opacity: 0;
        }
    }

    i {
        @media (max-width: 1024px) {
            display: none;
        }
    }
}
