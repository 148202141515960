﻿#healthbot-button {
    position: fixed;
    width: auto;
    height: 60px;
    bottom: 40px;
    right: 0px;
    text-align: center;
    line-height: 60px;
    background-color: #C32032;
    color: #FFF;
    border-radius: 2px;
    text-align: center;
    padding: 0px 15px;
    text-decoration: none;
}

#healthbot-content-button {
    height: 60px;
    text-align: center;
    line-height: 55px;
    background-color: #C32032;
    color: #FFF;
    border-radius: 2px;
    text-align: center;
    padding: 20px 15px;
    text-decoration: none;
}

#healthbot-container {
    width: 20%;
    position: fixed;
    width: auto;
    height: 100%;
    right: 0px;
    top: 31px;
    display: none;
    z-index: 1001;

    .floating-div {
        color: #EEE;
        font-size: 20px;

        .healthbot-header {
            background-color: #C32032;
            color: #FFF;
            width: 350px;
            display: flex;
            justify-content: space-between;
            border-left: 1px solid #000;
            border-right: 1px solid #000;
        }

        .title-wedge {
            width: 30px;
            margin: 0px;
            align-self: center;
        }

        .title {
            width: 100%;
            height: 35px;
            align-self: center;

            p {
                font-size: 1.4rem;
                font-size: 600;
                text-align: center;
                margin: auto
            }
        }

        .close-healthbot-wrapper {
            width: 30px;
            margin: 0px;
            align-self: center;

            .close-healthbot {
                color: white;

                &:active {
                    background-color: #C32032;
                }
            }
        }
    }

    .healthbot-iframe-wrapper {
        width: 350px;
        height: 100%;
        overflow: hidden;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
    }

    #healthbot-iframe {
        position: absolute;
        top: 35px;
        left: 0;
        width: 100%;
        height: 90%;
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
        border-left: 1px solid #000;
        border-top: 0
    }
}
