// Table

.table {
	margin: 0 0 39px;

	table {
		border: 0;
	}

	thead {
		background-color: $brand;
		color: $white;
		font-weight: 900;
		font-size: 1rem;
		text-transform: uppercase;
		text-align: left;
		line-height: 1;
		vertical-align: middle;

		th {
			padding: 12px 18px;
		}
	}

	tbody {
		border: 0;

		td {
			padding: 30px 18px;
			font-size: 1rem;
			vertical-align: middle;

			&:nth-child(1) {
				width: 23%;
			}

			&:nth-child(2) {
				width: 20.7%;
			}

			&:nth-child(3) {
				width: 23.7%;
			}

			&:nth-child(4) {
				width: 17.7%;
			}
		}
	}

	.table__actions {
		padding: 40px 0 0;
		text-align: center;
	}

	&--no-uppercase {
		thead {
			text-transform: none;
		}
	}

	&--alt {
		thead {
			text-transform: none;

			th {
				font-weight: 400;
			}
		}

		tbody {
			td {
				padding: 30px 18px;
				font-size: 1rem;
				vertical-align: middle;

				&:nth-child(1) {
					width: 16.5%;
				}

				&:nth-child(2) {
					width: 18%;
				}

				&:nth-child(3) {
					width: 17.5%;
				}

				&:nth-child(4) {
					width: 18.5%;
				}

				&:nth-child(5) {
					width: 17.5%;
				}
			}
		}
	}

	&--plan {
		thead {
			text-transform: none;

			th {
				font-weight: 400;
				text-transform: unset;
			}
		}

		tbody {
			td {
				padding: 30px 18px;
				font-size: 1rem;
				vertical-align: middle;

				&:nth-child(1) {
					width: 15.7%;
				}

				&:nth-child(2) {
					width: 42.7%;
				}

				&:nth-child(3) {
					width: 8.7%;
				}

				&:nth-child(4) {
					width: 9%;
				}

				&:nth-child(5) {
					width: 11%;
				}
			}
		}
	}

	@media #{$mobile} {
		margin: 0 -0.625rem 33px;

		&:last-child {
			margin-bottom: 0;

			table {
				margin-bottom: 0;
			}
		}

		thead {
			display: none;
		}

		table,
		tbody,
		tr,
		td {
			display: block;
		}

		tr {
			padding: 18px 0;
			border-top: 0;
			background-color: $grey;
		}

		tbody {
			td {
				width: 100% !important;
				padding: 0 16px 0;

				&:before {
					content: attr(data-title) ': ';
					display: inline-block;
					margin-bottom: 4px;
					text-transform: capitalize;
					font-weight: 900;
				}

				&:first-child {
					&:before {
						display: none;
					}
				}

				&[data-title='Notes'] {
					&:before {
						display: block;
					}
				}

				& ~ td {
					margin-top: 10px;
				}
			}
		}

		tbody.full-mobile {
			td {
				&:first-child {
					&:before {
						display: inherit;
					}
				}
			}
		}
	}
}
// Table Event
.table-event {
	margin: 0 0 30px;

	table {
		margin: 0;
		border: 0;

		tbody {
			border: 0;
		}

		tr {
			vertical-align: top;
		}

		td {
			padding: 0;

			& ~ td {
				padding: 0 5px;
			}
		}
	}
}

.small-table {
	width: 50%;

	@media #{$mobile} {
		width: 100%;
	}
}

// Table-fees

.table-fees {
	width: 100%;

	&:after {
		width: 40px;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		background: rgb(255, 255, 255);
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 60%,
			rgba(0, 0, 0, 0.2) 100%
		);
		z-index: 10;

		@media #{$mobile} {
			content: '';
		}
	}

	@media #{$mobile} {
		overflow-x: auto;
	}

	table {
		border: none;
		table-layout: auto;
		box-shadow: none;
		margin: 0;

		@media #{$mobile} {
			width: 738px;
		}
	}

	th,
	td {
		padding: 0 16px;
		border: 0;
		text-align: left;
	}

	th {
		font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
		font-weight: 400;
		font-size: 12px;
		line-height: 1.17;
		letter-spacing: 0;
		color: #ffffff;
		text-transform: uppercase;
		padding: 2px 16px;

		@media #{$mobile} {
			padding: 2px 10px;
		}
	}

	tr:nth-child(odd) {
		background-color: #f1f1f1;
	}

	tr:nth-child(even) {
		background-color: #fff;
	}

	tr:first-child {
		background-color: #002a3a;
		height:32px;
	}

	td {
		padding: 18px 16px;
		font-family: 'Avenir LT Std', sans-serif;
		font-weight: 500;
		font-size: 16px;
		line-height: 1.38;
		letter-spacing: 0;
		color: #555559;

		@media #{$mobile} {
			padding: 10px;
		}
	}
}
