﻿@import '../global/_variables.scss';

.article-header-container {
    padding-top: 64px;
}

.article {
    img {
        width: 100%;
    }

    ul.social-icons {
        list-style: none;
        margin: 0;

        li {
            display: inline-block;
            font-size: 1.6rem;
            color: $carleRed;
            margin-right: 2px;

            a {
                color: $carleRed;
            }
        }
    }

    .article-header-container {
        margin-bottom: 56px;

        .article-title {
            font-size: 3em;
            line-height: 1.1;
            margin-right: 36px;
            font-weight: normal;

            @include breakpoint(medium down) {
                font-size: 2em;
            }
        }

        .article-date {
            color: $carleRed;
            font-size: 1em;
            font-weight: $global-weight-bold;
        }
    }

    .section-header:after {
        margin-bottom: 32px;
    }

    .main-article-content {
        max-width: 80%;
        margin: 0 auto;

        @include breakpoint(medium down) {
            min-width: 100%;
        }

        blockquote {
            box-shadow: 0;
            margin: 0;
            padding: 0;
            border: 0;

            p {
                line-height: 1.6;
                color: #555559;
                border-left: 0;
                display: block;
            }
        }

        p {
            img {
                float: left;
                width: 33.333%;
                display: block;
                margin: 5px 30px 30px 0 !important;
            }
        }
    }
}


