// Form Donate

.form-donate {
	margin: 0 0 0 0;
	padding: 37px 24px;
	box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.1);
	background-color: $white;

	h4 {
		margin: 0 0 10px;
		font-family: $font-family-avenir;
		color: $carleRed;
		text-transform: uppercase;
		line-height: 1.2;
		font-weight: 700;
	}

	h5 {
		margin: 0 0 10px;
		font-family: $font-family-avenir;
		color: $carleRed;
		text-transform: uppercase;
		line-height: 1.2;
		font-weight: 700;
	}

	.form__head {
		margin: 0 0 24px;
		font-size: 1rem;
		line-height: 1.5;
	}

	.form__row {
		&--small {
			width: 56px;
			margin-right: 24px;

			&:last-child {
				margin-right: 0;
			}
		}

		&--field {
			width: 80px;
		}

		&--checkbox {
			& ~ .form__row--checkbox {
				margin-left: 15px;
			}
		}
	}

	.grid-x {
		& ~ .grid-x {
			margin-top: 11px;
		}
	}

	.form__actions {
		padding: 29px 0 0;

		.form__btn {
			padding: 14px 50px;
			cursor: pointer;
		}
	}

	@media #{$small-desktop} {
		.form__row {
			&--small {
				margin-right: 12px;

				&:last-child {
					margin-right: 0;
				}
			}

			&--checkbox {
				& ~ .form__row--checkbox {
					margin-left: 0px;
				}
			}
		}
	}

	@media #{$mobile} {
		padding: 20px 15px;

		h4 {
			font-size: 1rem;
		}

		.form__row {
			&--small {
				margin-right: 12px;
				margin-bottom: 12px;
			}
		}
	}
}

// Form Select

.o-form-select {
	padding: 12px 0 10px 0;

	.a-form__row {
		max-width: 320px;
		display: flex;
	}

	.a-form__controls {
		flex: 1;
	}

	.a-form__btn {
		flex: 0 0 48px;
		max-width: 48px;
		margin-left: 15px;
		height: 48px;
		padding: 5px 5px !important;
	}

	.a-form__actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 40px 20px 0 6px;

		&--careers-box {
			padding-top: 20px;
		}

		.a-form__link {
		}

		.a-btn-main {
			position: relative;
			flex: 0 0 192px;
			max-width: 192px;
			padding: 4px 19px 2px 26px;
			margin: 0 15px 0 0;
			font-size: 0.875rem;
			text-align: center;
			line-height: 1.36;

			i {
				position: absolute;
				left: 14px;
				top: 50%;
				transform: translateY(-50%);
				font-size: 10px;
			}
		}
	}

	@media #{$mobile} {
		.a-form__actions {
			display: block;
			padding: 20px 0;

			a {
				display: block;
			}

			.a-btn-main {
				margin: 0 0 10px;
				max-width: unset;
			}
		}
	}
}
//contact us form

.form {
	.field {
		height: 48px;
		border: 1px solid $carleMediumGrey;
		text-align: left;
		color: $maintextcolor;
		font-weight: 500;

		&--textarea {
			height: 105px;
			resize: none;
		}
	}

	.cols {
		display: flex;
		margin: 0 -12px;
	}

	.col {
		padding: 0 12px;

		&--size-1 {
			flex: 0 0 30%;
			max-width: 30%;
		}

		&--size-2 {
			flex: 0 0 35%;
			max-width: 35%;
		}

		&--1of2 {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}

	.a-select {
		font-size: 1rem;

		select {
			-webkit-appearance: none;
			padding: 0px 30px 0 12px;
		}
	}

	.form__label {
		display: block;
		margin: 0 0 6px;
		letter-spacing: 0.02em;
		font-size: 1rem;
		font-weight: 900;
	}

	.form__row {
		margin-top: 22px;

		& ~ .form__row {
			margin-top: 22px;
		}
	}

	.form__actions {
		padding: 32px 0 0;
	}

	.a-btn-main {
		min-width: 200px;
		padding: 14px 20px;
		cursor: pointer;
	}

	.field-validation-error {
		color: $carleRed;
		margin: 5px;
	}

	@media #{$mobile} {
		overflow: hidden;

		.cols {
			display: flex;
			margin: 0 -12px;
			flex-wrap: wrap;
		}

		.col {
			padding: 0 12px;

			&--size-1 {
				flex: 0 0 39%;
				max-width: 39%;
			}

			&--size-2 {
				flex: 0 0 46%;
				max-width: 46%;
			}

			&--moble-small {
				flex: 0 0 44%;
				max-width: 44%;
			}

			&--moble-large {
				flex: 0 0 56%;
				max-width: 56%;
			}
		}

		.a-select {
			margin-bottom: 16px;
		}

		.field {
			&--textarea {
				height: 121px;
				resize: none;
			}
		}

		.form__row {
			& ~ .form__row {
				margin-top: 14px;
			}

			&--select {
				margin-bottom: -16px;
			}
		}

		.form__actions {
			padding: 24px 0 0;
		}

		.a-btn-main {
			min-width: unset;
			width: 100%;
			padding: 10px 20px;
		}
	}
}

.form-builder-form {
	position: relative;

	.ktc-checkbox {
		label {
			display: inline-block;
		}
	}

	button[type='submit'] {
		@extend .a-btn-main;
	}

	.field-validation-error {
		color: $carleRed;
	}
}

// Form filters

.form-filters {
	//margin-bottom: 48px; -yk
	@media #{$mobile} {
		margin-bottom: 15px;
	}

	.form__cols {
		display: flex;
		margin: 0 -28px;

		@media #{$mobile} {
			display: block;
			margin: 0;
		}
	}

	.form__col {
		width: 100%;
	}

	.form__col--1of3 {
		max-width: 33.33%;
		flex: 0 0 33.33%;
		padding: 0 28px;

		@media #{$mobile} {
			max-width: 100%;
			margin-bottom: 15px;
			padding: 0;
		}
	}

	.form__label {
		margin-bottom: 13px;
		font-family: 'Avenir', sans-serif;
		font-weight: 900;
		font-size: 16px;
		line-height: 1.25;
		letter-spacing: 0;
		color: #555559;
	}

	.form__select {
		position: relative;

		strong {
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
		}

		select::-ms-expand {
			display: none;
		}
	}

	.form__field {
		width: 100%;
		height: auto;
		border: solid 1px #000;
		margin: 0;
		background: none;
		padding: 10px 16px;
		font-family: 'Avenir LT Std', sans-serif;
		font-weight: 300;
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: 0;
		color: #555559;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		text-indent: 1px;
		text-overflow: '';

		&:focus {
			outline: 2px solid $linkColor;
			outline-offset: 4px;
		}
	}
}
