// Slider Stories

.o-slider-stories {
	margin: 0 -130px;

	.slick-list  {
		overflow: visible !important;
	}

	.a-slider__slide {
		height: 100%;
		padding: 0 14px;
	}

	.slick-current {
		& + .slick-active {
			opacity: 1 !important;
		}
	}

	.slick-dots {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 30px 0 0;
		list-style-type: none;

		li {
			height: 9px;
			width: 9px;
			border: 1px solid $linkColor;
			border-radius: 50%;
			background-color: transparent;

			button {
				display: none;
			}

			& ~ li {
				margin-left: 7px;
			}
		}

		.slick-arrow {
			cursor: pointer;
		}

		.slick-arrow-prev {
			margin-right: 20px;
			color: $linkColor;
		}

		.slick-arrow-next {
			margin-left: 20px;
			color: $linkColor;
		}

		.slick-active {
			background-color: $linkColor;
		}
	}

	@media #{$mobile} {
		margin: 0;

		.slick-current {
			opacity: 1 !important;
		}
	}
}

