﻿.fap-results, .research-team {
    .search-results {
        list-style: none;
        margin: 0;

        &__item {
            @include elevation;

            .a-card-team {
                box-shadow: none;
            }
        }
    }

    .a-animate-opacity {
        opacity: 1;
        transition: opacity .25s ease-in-out;

        &--hidden {
            opacity: 0;
        }
    }
}

.fap-results {
    #searchResults {
        
        .search-results{
            padding: 0 32px;

            @include breakpoint(medium down){
                padding: 0;
            }
        }
    }
}

.query-not-found {
    border: 2px solid $carleRed;
    padding: 20px 30px 20px 50px;
    position: relative;

    &:before {
        content: '!';
        display: block;
        width: 48px;
        height: 48px;
        line-height: 1;
        padding-top: 3px;
        background: $carleDarkBlue;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 48px;
        position: absolute;
        left: -24px;
        top: 50%;
        transform: translateY(-50%);
    }
}