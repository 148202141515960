

















































.a-open-panel{
    > img {
        position: relative;
        top: -1px;
    }
    > span {
        color: #555559;
    }
}
.shortest-waits{
    list-style: none;
    margin: 0;

    &__location {
        margin: 0 0 1rem;

        .a-external-link{
            font-size: 0.875rem;
        }
    }
}
