﻿@import "../global/_variables.scss";

.careers-page {
    padding-top: 64px;

    @include breakpoint(medium down) {
        padding-top: 32px;
    }

    .m-feature {
        .a-btn-main {
            margin: 16px 0;
        }

        & > .grid-x {
            margin-bottom: 0px;
            padding-bottom: 60px;
            border-bottom: 1px solid #555559;

            @include breakpoint(medium down) {
                padding-bottom: 0;
            }
        }
    }
}

.careers-why-choose-carle {
    .why-choose-carle-main {
        margin: 64px auto 0;

        @include breakpoint(large down) {
            margin: 24px auto;
        }
    }
}

.careers-nursing {
    .o-section-cards {
        padding-bottom: 0px
    }

}

.careers-single {
    .narrow-section {
        margin: 64px auto 0;

        @include breakpoint(large down) {
            margin: 24px auto 0 !important;
        }
    }

    .m-card {
        height: 100%;
        padding-bottom: 16px;
        margin-bottom: 0;

        h3 {
            margin: 0 0 12px;
            font-family: linotype-didot-roman;
            font-size: 1.5rem;
            line-height: 1.29;
        }
    }
}

.greybox {
    width: 75%; 
    background-color: $carleLightGrey;
    padding: 24px;
    margin: 12px 0 16px;
}

.shadow-box {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.1);
    padding: 24px 24px 12px;
    background-color: white;
}

.training-program {
    margin-top: 48px;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 48px;
    }
}

.careers-single {
    .a-btn-main {
        text-transform: uppercase;
    }

    .o-footer {
        margin-top: 64px;

        @include breakpoint(large down) {
            margin-top: 0;
        }
    }

    ul.fifty-fifty-list {
        margin-bottom: 0;

        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }

    .grid-container-small {
        &:first-child {
            .icon-header {
                margin-top: 0;
            }
        }
    }

    .icon-header {
        color: $maintextcolor;
        padding-left: 0px;
        min-height: 100px;
        padding-top: 15px;
        background-repeat: no-repeat;
        margin-top: 48px;
        margin-bottom: 12px;

        @include breakpoint(large down) {
            padding-top: 100px;
            background-position: center top;
            padding-left: 0;
            text-align: center;
        }

        &.wellness {
            background-image: url('/Build/Images/wellness-icon.png');
        }

        &.my-time {
            background-image: url('/Build/Images/my-time-icon.png');
        }

        &.my-life {
            background-image: url('/Build/Images/my-life-icon.png');
        }

        &.my-finances {
            background-image: url('/Build/Images/my-finances-icon.png');
        }
    }
}



.careers {

    .m-feature-media .m-feature-media__actions {
        width: auto;

        .row {
            .button-cell {
                width: auto;
            }
        }
    }

    .o-intro-textarea {
        max-height: 400px;
        max-width: unset !important;

        @media #{(max-width: #{$responsive-xs})} {
            padding: 0px 0px 20px;
            font-size: 1.4rem;
            position: relative;
            bottom: -20px;
            left: 10px;

            h2 {
                font-size: 1rem;
                line-height: .9;
            }
        }

        @include breakpoint(large down) {
            a.a-btn-main {
                display: none;
            }
        }

        a.a-btn-main {
            margin-top: 24px;
        }

        .hero-copy {
            z-index: 2;
            position: absolute;
            height: 100%;
            padding-top: 3%;
            width: 94%;
            left: 20%;

            @include breakpoint(large down) {
                width: 94%;
                padding: 3%;
                left: 0;
                bottom: 0;
            }

            @include breakpoint(medium down) {
                width: 94%;
                padding: 3%;
                left: 0;
                bottom: 0;
            }
        }
    }

    .a-anchor-id {
        top: -155px;
    }

    .narrow-section {
        margin: 64px auto 0;

        @include breakpoint(large down) {
            margin: 24px auto 0 !important;
        }
    }

    .a-btn-main {
        text-transform: uppercase;
    }

    .page-hero {
        @include breakpoint(medium down) {
            a.a-btn-main {
                display: none;
            }
        }

        .careers-hero-text {
            z-index: 2;
            position: absolute;
            height: 100%;
            padding-top: 3%;
            width: 100%;
            left: 20%;

            @include breakpoint(large down) {
                width: 94%;
                padding: 3%;
                left: 0;
                bottom: 0;
            }

            @include breakpoint(medium down) {
                width: 94%;
                padding: 3%;
                left: 0;
                bottom: 0;
            }
        }
    }

    .m-feature-media__actions .button-cell a {
        width: 160px;
        padding: 11px 28px !important;
        margin-right: 16px;
        text-align: center;

        @include breakpoint(large down) {
            width: 100%;
            margin-right: 0;
        }
    }

    .a-select:after {
        right: 45%;

        @include breakpoint(large down) {
            right: 5%;
        }
    }

    .o-section-awards {
        padding-bottom: 0;

        .list-awards {
            margin-bottom: 16px;
            margin-top: 16px;
        }
    }

    .negate-p {
        p {
            line-height: 1.6;
            margin-bottom: 1rem;
        }
    }

    .page-header {
        p {
            margin: 24px 0;
        }

        .m-search {
            height: auto !important;
            transform: scaleY(1) !important;
        }

        .m-search__btn {
            position: absolute;
            top: initial;
            right: 21%;
            bottom: 4px;
            height: 40px;
        }

        .m-search__field {
            width: 80%;

            @include breakpoint(large down) {
                width: 100%;
            }
        }
    }

    &.three-columns:last-child {
        margin-bottom: 48px;
    }

    .three-column-card-grid {
        margin: 4px auto;

        .m-card {
            height: 100%;
            margin-bottom: 0;
            padding-bottom: 12px;

            h3 {
                font-size: 1rem;
                font-weight: $global-weight-bold;
            }
        }
    }

    .page-header {
        margin-top: 32px;
        padding: 0;

        @include breakpoint(large down) {
            margin-top: 0;
            margin-bottom: 24px;
        }

        h1 {
            width: 75%;

            @include breakpoint(large down) {
                width: 100%;
            }
        }

        .large-offset-1 {
            border-left: 1px solid #d8d8d8;
            padding-left: 48px;
            padding-top: 32px;
            padding-bottom: 32px;

            @include breakpoint(medium down) {
                padding-left: 0;
                border: 0;
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .large-6 {
            padding: 32px 0;
        }

        .search-wrap {
            position: relative;

            &:before {
                position: absolute;
                top: 9px;
                left: 10px;
                content: "\f002";
                font-size: 1.3rem;
                font-family: FontAwesome;
            }

            input#job-search {
                width: 100%;
                padding-left: 40px;
            }
        }

        select#job-category {
            width: 60%;

            @include breakpoint(large down) {
                width: 100%;
            }
        }

        .button-wrap {
            margin-top: 16px;

            label {
                margin-bottom: 16px;
            }

            .a-btn-main {
                text-align: center;
                height: 48px;
                padding: 13px 0;
                width: 60%;
                margin-bottom: 16px;
                margin-top: 12px;

                @include breakpoint(large down) {
                    width: 100%;
                }

                &:first-child {
                    margin-bottom: 16px;
                }
            }

            &.secondary-buttons {
                &:before {
                    display: block;
                    content: '';
                    height: 1px;
                    width: 105%;
                    background-color: #d8d8d8;
                    margin-bottom: 32px;
                    position: relative;
                    left: -5%;

                    @include breakpoint(large down) {
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}

.careers-results {
    table tbody td {
        &:nth-child(1) {
            width: auto;
        }

        &:nth-child(2) {
            width: auto;
        }

        &:nth-child(3) {
            width: auto;
        }

        &:nth-child(4) {
            width: auto;
        }
    }


    .careers-results-count {
        margin-top: 50px;
        font-size: 1rem;

        @include breakpoint(large down) {
            margin-top: 0px;
        }
    }

    .table tbody td {
        padding: 16px 10px;

        @include breakpoint(medium down) {
            padding: 0 16px 0;
        }
    }

    .sort {
        height: 45px;
        float: right;
        position: relative;
        top: -15px;
        max-width: 250px;

        @include breakpoint(medium down) {
            float: none;
            top: 0;
            margin: 32px 0 0;
            max-width: unset;
        }

        &:after {
            right: 20px;
        }

        select {
            height: 45px;
            min-width: 250px;

            @include breakpoint(large down) {
                max-width: unset;
                width: 100%;
            }
        }
    }

    a.page-link:focus {
        background: transparent;
    }

    a.page-link.page-link--current {
        background: #e6e6e6;
    }

    .loadingArea {
        min-height: 400px;
        background: #f1f1f1;
        text-align: center;

        p:first-child {
            color: $carleRed;
            padding-top: 134px;
            font-size: 1.5rem;
        }

        p:last-child {
            font-size: 1.5rem;
            font-weight: $global-weight-bold;
        }
    }

    .a-select {
        height: 100px;

        &:after {
            top: 50%;
        }
    }

    .page-header {
        padding-top: 84px;
        margin-bottom: 24px;
    }

    label {
        font-weight: $global-weight-bold;
        color: $maintextcolor;
        display: block;
        margin-bottom: 8px;
    }

    .search-main-wrap {
        display: inline-block;
        margin-right: 24px;
        position: relative;
        width: 100%;

        @include breakpoint(large down) {
            margin-right: 0;
        }

        .checkbox {
            margin-top: 16px;
        }

        .search-wrap {
            width: 100%;
            max-width: 300px;
            position: relative;
            display: inline-block;

            @include breakpoint(large down) {
                max-width: unset;
            }

            input {
                @include breakpoint(large down) {
                    max-width: unset !important;
                }
            }

            &:hover {
                .m-search__btn {
                    background-color: $carleDarkBlue;
                }
            }

            .m-search__btn {
                position: absolute;
                top: 3px;
                right: 3px;
                height: 42px;
            }

            input {
                width: 100%;
                height: 48px;
                padding: 0 0 0 15px;
                margin-bottom: 0;
            }
        }
    }

    .filter-open {
        display: inline-block;
        line-height: 48px;
        padding-left: 30px;
        position: absolute;
        left: 300px;
        top: 30px;

        @include breakpoint(large down) {
            padding-left: 0;
            left: 0;
            position: relative;
            top: 0;
        }

        a.filter-toggle {
            display:inline-block;
            margin-right: 20px;

            &:after {
                color: $linkColor;
                margin-left: 8px;
                font-family: FontAwesome;
                content: "\f0d7";
                display: inline-block;
                line-height: 48px;
            }
        }



        &.opened {
            a .filter-toggle {
                &:after {
                    font-family: FontAwesome;
                    content: "\f0d8";
                }
            }
        }
    }

    .table-wrap {
        display: table;
        width: 100%;
        border: 0;
        background-color: #fafafa;

        .table-header-row {
            display: table-row;

            .table-header-cell {
                padding: 12px;
                display: table-cell;
                background-color: $carleDarkBlue;
                color: white;
            }
        }

        .table-row {
            display: table-row;

            &:nth-child(odd) {
                background-color: #f1f1f1;
            }

            .table-cell {
                padding: 12px;
                display: table-cell;
            }
        }
    }
}

.job-details {
    .large-6 {
        margin-bottom: 20px;
    }
}

.careers-community {
    .gm-style .gm-style-iw-c {
        border-radius: 0;
        padding: 0;
    }
}

.advanced-filter {
    display: none;
    margin: 40px 0 24px;

    @include breakpoint(large down) {
        margin: 16px 0 24px;
    }

    input[type=text] {
        margin: 0;
        width: 30%;
        margin-right: 3%;
        height: 48px;
        padding-right: 2rem;
        font-weight: 300;
        color: #555559;
        background-image: none;
        border: 1px solid #555559;
    }

    &.show {
        display: block !important;
    }

    .select-wrap {
        display: inline-block;
        width: 30%;
        margin-right: 3%;

        @include breakpoint(large down) {
            width: 47%;
        }

        @include breakpoint(medium down) {
            width: 100%;
            margin-right: 0;
        }

        select {
            height: 48px;
        }
    }
}