﻿#hcn_frame_content {
    width: 100%;
    min-height: 1500px;

    @media (max-width: 650px) {
        min-height: 3250px;
    }


    @media (max-width: 565px) {
        min-height: 3100px;
    }

    border: 0px;
}
