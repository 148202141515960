﻿.provider-heading{
    margin-top: 48px;
    margin-bottom: 48px;
}

.m-provider{
    padding-top: 48px;
}

.provider-card {
    @include elevation();
    padding: 25px;
    margin-bottom: 70px;

    &__profile {
        border-right: 1px solid $carleMediumGrey;

        .fullname {
            font-size: 1.5rem;
            font-weight: 900;

            @media #{$mobile} {
                padding-top: 1rem;
            }
        }

        @media #{$mobile} {
            border-right: 0;
            text-align: center;
        }
    }

    &__image img {
        width: 100%;
        max-width: 175px;
    }
}

.provider-details {
    padding: 0 25px;

    .provider-detail {
        &__heading {
            color: $carleRed;
            font-size: 0.875rem;
            font-weight: 900;
            text-transform: uppercase;
        }

        &__list {
            line-height: 2rem;
        }
    }
}

.m-star-rating {
    width: 100%;
    background-color: #F1F1F1;
    padding: 8px;

    &__anchor {
        color: $maintextcolor;
        font-weight: normal;

        &:hover {
            color: $maintextcolor;
            text-decoration: none;
        }
    }

    &__star{
        margin-right: 4px;
    }

    @include breakpoint(medium down) {
        margin-bottom: 16px;
    }
}

.m-reviews {
    > .grid-x {
        > .cell {
            margin-top: 48px;
            padding-top: 48px;
            border-top: 1px solid #555559;
        }
    }
    h2{
        margin-bottom: 32px;
    }
    &__review{
        margin-bottom: 40px;
    }
}