/* ------------------------------------------------------------ *\
	Hero-login
\* ------------------------------------------------------------ */

.hero-login {
	min-height: 472px;
	background-size: cover;
	background-color: #d6d6d6;
	background-repeat: no-repeat;
	background-position: center center;
	position: relative;
	overflow: hidden;
	padding: 152px 0 140px;

	@media #{$mobile} {
		padding: 80px 0;
		min-height: 0;
	}

	@media #{$mobile-small} {
		padding: 40px 0;
	}

	.hero__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 5;

		@media #{$mobile} {
			display: block;
		}
	}

	.hero__title {
		padding: 28px 0 8px;

		@media #{$mobile} {
			text-align: center;
			padding: 0;
			margin-bottom: 0;
		}
	}

	.hero__title p {
		background-color: #002a3a;
		display: inline-block;
		font-family: 'Avenir', sans-serif;
		font-weight: 900;
		font-size: 48px;
		line-height: 1.34;
		letter-spacing: 0;
		color: #fff;
		padding: 0 10px;
		margin: 0;

		@media #{$mobile} {
			font-size: 36px;
		}

		@media #{$mobile-small} {
			font-size: 27px;
		}

		&:first-child {
			margin: 0 0 8px;
		}
	}

	.hero__actions {
		width: 100%;
		max-width: 352px;
		background-color: #fff;
		box-shadow: 0 5px 8px 3px rgba(85, 85, 89, 0.07);
		padding: 26px 32px 40px;

		@media #{$mobile} {
			margin: 0 auto;
			display: none;
		}
	}

	.hero__actions-title {
		margin-bottom: 34px;
		text-align: center;
		font-family: 'GFS Didot', serif;
		font-weight: 400;
		font-size: 25px;
		line-height: 1.5;
		letter-spacing: 0.035em;
		color: #555559;
	}

	.hero__btn {
		width: 100%;
		max-width: 289px;
		border: solid 3px #000;
		background-color: #fff;
		display: block;
		position: relative;
		padding: 15px;
		text-align: center;
		font-family: 'Avenir LT Std', sans-serif;
		font-weight: 300;
		font-size: 16px;
		line-height: 1.25;
		letter-spacing: 0;
		color: #ffffff;
		transition: color 0.3s;

		span {
			width: 100%;
			display: block;
			position: relative;
			z-index: 10;
		}

		&:after {
			content: '';
			width: 100%;
			height: 100%;
			background-color: #000;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 9;
			display: block;
			transform-origin: right;
			transition: transform 0.4s;
		}

		&:focus {
			outline: 2px solid $linkColor;
			outline-offset: 4px;
		}

		&:hover {
			color: #000;
			text-decoration: none;
		}

		&:hover:after {
			transform: scaleX(0);
		}
	}

	.hero__btn img {
		width: 26px;
		height: 24px;
		position: absolute;
		left: -5px;
		top: -1px;
	}

	video {
		position: absolute;
		object-fit: cover;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
	}
}
