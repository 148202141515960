































































































































































































.root {
    padding: 1rem;
}

.selected-media {
    display: flex;
    align-items: flex-end;
}

    .selected-media > * {
        margin-right: 1rem;
    }

.card {
    justify-content: space-between;
}

.card-img-top {
    padding-top: 0.5rem;
    width: auto;
}

.card-footer {
    display: flex;
    justify-content: space-between;
}

.modal {
    z-index: 10011; /* one above Kentico's widget header z-index */
}
