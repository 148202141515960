// Tiles

.o-tiles {
	.grid-x {
		margin: 0 -24px;
		justify-content: center;
	}

	.cell {
		display: flex;
		padding: 0 24px;
	}

   a {

       display:flex;
       flex:auto;

   }

	@media #{$large-desktop} {
		.grid-x {
			margin: 0 -12px;
		}

		.cell {
			padding: 0 12px;
		}
	}

	@media #{$mobile} {
		.grid-x {
			margin: 0;
		}

		.cell {
			padding: 0;
		}
	}
}


//  Tile

.o-tile {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 25px 10px 22px;
    text-align: center;
    background-color: $white;
    box-shadow: 0 8px 17px 0 rgba(85,85,89, .1);
    transition: box-shadow .3s ease;

    &:hover {
        box-shadow: 0 0px 20px 0 rgba(85,85,89, .5);
    }

    h5 {
        color: $linkColor;
        font-weight: 900;
        font-family: $font-family-avenir;
        line-height: 1.25;
    }

    h4 {
        color: $linkColor;
        font-weight: 900;
        font-family: $font-family-avenir;
        line-height: 1.25;
    }

    a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .a-tile__ico {
        min-height: 59px;
        margin: 0 0 5px;
        line-height: 59px;
    }

    @media #{$mobile} {
        max-width: 152px;
        margin: 0 auto 25px;
        padding: 25px 10px 22px;
    }
}
