﻿// background colors:

$carleRed: #c32032;
$carleLightGrey: #f9f9f9;
$carleMediumGrey: #d8d8d8;
$carleDarkBlue: #002a3a;
$white: #fff;
$brand: #002a3a;
$grey: #fafafa;
$grey-100: #8b8b8e;

// text colors:
$maintextcolor: #555559;
$linkColor: #037d96;
$linkHoverColor: #002a3a;
$carleButtonHoverColor: #900000;
$topNavLinkHoverColor: #e56171;
$focusColor: #ffb900;

// horizontal rules
$darkRuleLine: #ccccce;

//font sizes

$h1Size: 3rem;
$h2Size: 3rem;
$h3Size: 1.5rem;
$h4Size: 1.25rem;
$h5Size: 1rem;
$h6Size: 0.875rem;

// Font Family

$font-family-avenir: 'Avenir LT W01_65 Medium1475532', sans-serif;
$font-family-gfs-didot: linotype-didot-roman, serif;

$responsive-xxs: 479px;
$responsive-xs: 767px;
$responsive-sm: 1023px;
$responsive-md: 1200px;
$responsive-lg: 1280px;

$mobile-small: '(max-width: #{$responsive-xxs}) ';
$mobile-medium: '(max-width: #{$responsive-xxs}) ';
$mobile: '(max-width: #{$responsive-xs}) ';
$tablet-portrait: '(max-width: #{$responsive-sm}) ';
$small-desktop: '(max-width: #{$responsive-md}) ';
$large-desktop: '(max-width: #{$responsive-lg}) ';
