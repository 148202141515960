// Logo

.a-logo {
    display: inline-block;
    flex: 0 0 120px;
    width: 120px;
    height: 32px;
    margin-left: -10px;
    background-size: cover;
    background-position: center center;

    @media #{$mobile} {
        flex: 0 0 60px;
        width: 60px;
        height: 16px;
        margin-left: 33px;
    }
}

.logo-small {
    display: inline-block;
    width: 53px;
    height: 51px;
    margin-left: 0px;
    background-position: center center;
}

.m-logo-small-chpp {
    color: #fff;
    font-weight: 400;

    &:hover {
        text-decoration: underline;
        color: #fff;
    }
}

.logo-chpp {
    @media (max-width: 1024px) {
        margin-left: 0px !important;
    }
}
