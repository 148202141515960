﻿.o-visiting-specialists {
    margin-top: 24px;

    .m-visiting-specialists {
        line-height: 1.5;

        &__header {
            margin-bottom: 32px;
            text-align: center;

            h5, .h5 {
                display: inline-block;
                color: $maintextcolor;
                font-weight: 900;
                text-transform: uppercase;
                font-family: 'Avenir LT W01_85 Heavy1475544';
            }

            .a-title-underline {
                padding: 0 0 27px;

                &:after {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        &__body {
            .specialists {
                &__specialist {
                    margin-top: 16px;
                    margin-bottom: 24px;
                    padding-left: 52px;
                    padding-right: 51px;
                    border-right: 1px solid $carleMediumGrey;

                    &:nth-child(3n) {
                        border-right: 0 none;
                    }

                    @media #{$mobile} {
                        border-right: 0 none;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

.refer-a-patient {
    .a-feature-content {
        padding-right: 0;

        h3 {
            font-size: 2rem;
        }
    }

    .o-sidebar {
        padding-left: 32px;
    }

    .a-ul--columns-3 {
        li {
            margin-bottom: 0.5rem;
        }
    }

    @include breakpoint(medium down) {
        .o-sidebar {
            padding-left: 0;
            border-left: 0 none;

            .grid-container, .o-simple-text-container {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    @include breakpoint(small only) {
        .a-ul--columns-3 {
            // columns: auto 1;
            column-width: auto;
            column-count: 1;
            margin: 0;
        }
    }
}