// Tabs
.m-tabs {
    margin: 0 0 61px;

    .a-tabs__head {
        background-color: $brand;
    }

    button {
        cursor: pointer;
    }

    .a-tabs__nav {
        padding: 0 46px;

        ul {
            display: flex;
            margin: 0;
            list-style-type: none;
        }

        button > * {
            pointer-events: none;
        }


        button {
            position: relative;
            flex: 0 0 19.5%;
            max-width: 19.5%;
            display: block;
            padding: 17px 10px 10px;
            color: $white;
            text-align: center;
            font-size: 1rem;
            transition: background-color .3s ease;
            font-weight: 700;
            line-height: 1.25;
            background-color: transparent;




            &:hover {
                text-decoration: none;
                background-color: $linkColor;
            }

            &:focus {
                background-color: $linkColor;
            }

            i {
                font-size: 30px;
            }

            span {
                display: block;
                height: 34px;
                margin: 0 0 5px;
                line-height: 34px;
            }
        }
    }

    @media #{$mobile} {
        margin: 0 0 10px;

        .a-tabs__head {
            display: none;
        }
    }

    .a-tabs__actions {
        position: relative;
        padding-left: 91px;
        top: -20px;

        button {
            display: inline-block;
            position: relative;
            width: 49px;
            height: 49px;
            border-radius: 50%;
            border: 1px solid $maintextcolor;
            color: $maintextcolor;
            z-index: 2;
            transition: color .3s ease, border-color .3s ease;

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            & ~ button {
                margin-left: 31px;
            }

            &:hover {
                color: $carleRed;
                border-color: $carleRed;
            }
        }
    }

    @media #{$mobile} {
        padding: 0 0 0px;

        .grid-container {
            padding: 0;
        }

        .a-tabs__actions {
            display: none;
            padding: 30px 0;

            a {
                width: 39px;
                height: 39px;
            }
        }
    }
}

// Tab

.a-tab {
    padding: 91px 0 0;
    min-height: 623px;

    .m-feature {
        margin: 0;
        padding: 0 22px 0 91px;

        .e-feature__head {
            display: none;
        }

        p {
            margin: 0 0 19px;
            line-height: 1.25;
        }

        h2,h3 {
            margin: 0 0 32px;
        }

        h3 {

            font-size: 3rem;
        }
        

        .a-feature-content {
            padding: 9px 60px 0 0;
        }

        .a-feature-images {
            padding: 4px 26px 0 27px;
        }

        .a-feature-actions {
            padding: 55px 0 0;
        }

        .a-btn-main {
            padding: 11px 18px;
            font-weight:900;
        }
    }

    &.current {
        display: block;
    }

    @media #{$mobile} {
        padding: 0px 0 0;
        display: block;
        min-height: auto;

        .m-feature {
            padding: 0;

            .e-feature__head {
                display: block;
                position: relative;
                background-color: $brand;
                padding: 31px 20px 28px 72px;
                color: $white;
                cursor: pointer;
                transition: background-color .3s ease;
                box-shadow: inset 0px 0px 1px 0px rgba(255,255,255, .5);

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 5px 3px 0 3px;
                    transform: translateY(-50%);
                    border-color: $white transparent transparent transparent;
                    transition: transform .3s ease;
                }

                h3 {
                    margin: 0;
                    line-height: 1;
                    font-size: 0.875rem;
                    font-family: $font-family-avenir;
                }

                img {
                    position: absolute;
                    left: 27px;
                    top: 20px;
                    font-size: 26px;
                }

                &.active {
                    background-color: $linkColor;

                    &:after {
                        transform: translateX(40%) rotate(-90deg);
                    }
                }
            }

            h2, h3.a-title-underline {
                display: none;
            }

            .a-feature-content {
                display: none;
                padding: 20px 20px;
            }

            .a-feature-images {
                display: none;
            }

            .e-feature-image {
                display: none;
                margin: 0;
                padding: 0;
            }

            .a-feature-actions {
                padding: 25px 0 0;
            }

            .a-btn-main {
                width: 100%;
                padding: 11px 10px;
                text-align: center;
            }
        }
    }
}

[role="tablist"] {
    margin: 0 0 -0.1em;
    overflow: visible;
}

[role="tab"] {
    position: relative;
    overflow: visible;
}

[role="tab"]:hover::before,
[role="tab"]:focus::before,
[role="tab"][aria-selected="true"]::before {
    position: absolute;
    bottom: 100%;
    right: -1px;
    left: -1px;
    border-radius: 0.2em 0.2em 0 0;
    // border-top: 3px solid hsl(20, 96%, 48%);
    content: '';
}

[role="tab"]:hover::after,
[role="tab"]:focus::after,
[role="tab"][aria-selected="true"]::after {
     
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-style: solid;
        border-width: 16px 15px 0 15px;
        border-color: $linkColor transparent transparent transparent;
        opacity: 1;
     
 
}



[role="tabpanel"] {
    position: relative;
    z-index: 2;
}

[role="tabpanel"]:focus {
    border: 1px solid $linkColor;
}

[role="tab"][aria-selected="true"] {
    background-color: $linkColor !important;
}
