//Giving

.giving {
	.main {
		padding: 0;
	}
}

.giving-page {
    padding-top: 104px;
}
