﻿.o-donation-form {
    border-radius: 4px;
    box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.08);
    padding: 24px 24px 32px 24px;
    background-color: white;
    margin: 80px auto 50px;

    h3 {
        margin-bottom: 15px;
    }

    .a-category-list-item-button {
        width: 22%;
        margin: 10px 1%;
        text-align: center;
        height: 60px;
        padding: 19px 0 16px;
    }
}

.giving {
    //.articles-slider {
    //    h2.section-header-center {
    //        margin-top: 80px;
    //        margin-bottom: 40px;
    //    }
    //}
}

.info-graphics img {
    display: none;

    &.showOnDesktop {


        @include breakpoint(large up) {
            display: inline;
        }
    }

    &.showOnTablet {


        @include breakpoint(medium only) {
            display: inline;
        }
    }

    &.showOnMobile {


        @include breakpoint(small only) {
            display: inline;
        }
    }
}