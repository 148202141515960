// Breadcrumbs

.o-breadcrumbs {
    position: relative;
    margin: 0 0 0 -45px;
    padding: 20px 0 0;
    font-size: pxToResponsiveUnit(14px);
    line-height: 1.36;
    font-weight: 700;

    a {
        color: $linkColor;
    }

    .current {
        a {
            pointer-events: none;
            color: $maintextcolor;
        }
    }

    ul {
        display: flex;
        margin: 0;
        list-style-type: none;
    }

    li {
        & ~ li {
            margin-left: 4px;
        }
    }

    @media #{$large-desktop} {
        margin: 0;
    }

    @media #{$mobile} {
        display: none;
        margin: 0 0 20px;
    }

    #sfcri-link {
        display: none !important;
        margin-top: 16px;

        @at-root .single-provider & {
            display: block !important;
        }
    }
}

.breadcrumbs--new {
    font-weight: 600;
    margin: 0;
    padding: 12px 0 0 32px;

    a {
        font-weight: 600;
    }
}
