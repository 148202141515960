﻿@font-face {
	font-family: 'Avenir LT Std';
	src: url('../../Build/Fonts/AvenirLTStd-Medium.eot');
	src: url('../../Build/Fonts/AvenirLTStd-Medium.eot?#iefix')
			format('embedded-opentype'),
		url('../../Build/Fonts/AvenirLTStd-Medium.woff2') format('woff2'),
		url('../../Build/Fonts/AvenirLTStd-Medium.woff') format('woff'),
		url('../../Build/Fonts/AvenirLTStd-Medium.svg#AvenirLTStd-Medium')
			format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../../Build/Fonts/Avenir-Black.woff2') format('woff2'),
		url('../../Build/Fonts/Avenir-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Avenir LT Std';
	src: url('../../Build/Fonts/AvenirLTStd-Book.woff2') format('woff2'),
		url('../../Build/Fonts/AvenirLTStd-Book.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Avenir';
	src: url('../../Build/Fonts/Avenir-Heavy.woff2') format('woff2'),
		url('../../Build/Fonts/Avenir-Heavy.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}
