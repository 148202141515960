@import '_variables.scss';

// Contacts

.a-contacts {
    font-size: 1rem;
    padding: 8px 0 0;

    ul {
        margin: 0;
        display: flex;
        list-style-type: none;
        letter-spacing: .015em;

        img {
            position:relative;
            top: -2px;
        }
    }

    li {
        & ~ li {
            margin-left: 35px;
        }
    }

    a {
        font-weight: 300;
        transition: color .3s ease;

        i {
            display: inline-block;
            margin-right: 1px;
            color: $carleRed;
        }

        span {
            span {
                color: $linkColor;
                font-weight: 900;
            }
        }

        &:hover {
            text-decoration: none;
            color: $carleRed;

            span {
                color: $carleRed;
            }
        }
    }

    .fa-clock-o, .fa-map-marker {
        font-size: 19px;
    }

    .a-fax {
        color: $maintextcolor;
        font-weight: 900;

        i {
            display: inline-block;
            margin-right: 5px;
            color: $linkColor;
            font-size: 18px;
        }
    }

    @media #{$small-desktop} {
        font-size: 1rem;

        li {
            & ~ li {
                margin-left: 10px;
            }
        }
    }

    @media #{$mobile} {
        padding: 10px 10px;
        background-color: $grey;

        ul {
            display: block;
            text-align: left;
        }

        li {
            & ~ li {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }
}
