﻿@import '_variables.scss';

.o-featured-item {
    background-size: cover;
    background-position: center center;
    margin: 0 auto;

    .grid-container.m-featured-item-content {
        margin: 60px auto;

        .large-5 {
            background-color: white;
            padding: 32px 32px 40px;

            @include breakpoint(large down) {
                padding: 16px;
            }

            h2.a-featured-item-headline {
                line-height: 1.2;
            }

            .a-featured-item-text {
                margin-bottom: 32px;
            }
        }
    }
}

.m-card-two-cards {
    .m-card {
        h3 {
            font-size: 1.25rem;
        }
    }
}


.o-giving-callout-large {
    padding: 64px 0;

    .large-text-left {
        p {
            font-size: 1.5em;
            line-height: 1.4;
            color: $maintextcolor;
        }
    }
}

.o-giving-simple-callout {
    background-color: $carleDarkBlue;
    padding: 64px 0;
    color: white;
    text-align: center;

    p {
        margin: 24px 0 0;

        a.a-btn-main {
            margin: 5px 10px;
            display: inline-block;

            @include breakpoint(large down) {
                margin: 0 0 10px !important;
            }
        }
    }
}

.m-admin-fellows {
    .fellow {
        margin-bottom: 32px;
        padding-right: 32px;
    }

    &__image {

    }

    &__content {
        margin-top: 16px;
    }
}

.slick-slide {
    margin: 0 12px;
}

.slick-list {
    margin: 0 -12px !important;
}

.m-section-entry-quote {
    padding: 24px 0;
}

.m-quote-box.m-card-media {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.1);

    .grid-x {
        flex-direction: initial;
    }

    .m-card-media__image {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center center;
    }

    .m-card-media__content {
        box-shadow: none;
        padding: 24px 20px 24px 30px;

        @include breakpoint(large down) {
            padding: 24px 0.675rem 44px 0.675rem;
        }
    }

    h2.large-sans-serif {
        font-family: $font-family-avenir;
        font-size: $h3Size;
        font-weight: $global-weight-bold;
        color: $maintextcolor;
        min-height:100px;
    }

    p.red-text {
        color: $carleRed;
        font-weight: $global-weight-bold;
        margin-bottom: 0;
        margin-top: 32px;
    }

    strong {
        font-weight: $global-weight-bold;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.o-events-widget {

}

.m-event-card {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    height: 100%;
    position: relative;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    align-items: stretch;

    .calendar-icon {
        position: absolute;
        bottom: 12px;
        right: 16px;

        i {
            color: $carleRed;
            font-size: 1.4rem;
        }
    }

    &:before {
        content: '';
        height: 80%;
        position: absolute;
        width: 4px;
        left: 0;
        background-color: $carleRed;
        z-index: 100;
        top: 10%;
    }

    &_details {
        padding: 10px 32px 10px 24px;
        width: 100%;
        height: 60px;
    }

    &_header {
        position: relative;
        color: white;
        font-weight: $global-weight-bold;
        /* margin-bottom: 24px; */
        background-color: #002A3A;
        /* margin-top: 20px; */
        padding: 10px 32px 10px 24px;
        width: 100%;
        flex-grow: 1;


        a {
            color: white;
            display: inline-block;
        }

        .m-event-card_datebox {
            position: absolute;
            box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.1);
            background-color: white;
            height: 40px;
            width: 40px;
            text-align: center;
            left: 15px;
            top: 0;
            color: $carleRed;
            font-size: 0.8rem;
            text-transform: uppercase;

            .m-event-card_datenumber {
                display: block;
                text-align: center;
                width: 100%;
                color: $maintextcolor;
            }
        }
    }

    &_image {
        min-height: 150px;
        background-color: darkgrey;
        width: 100%;
    }
}

.o-grid-card {
    box-shadow: 0 8px 17px 0 rgba(0,0,0,0.1);

    @at-root .m-icon-card {
        &--small {
            width: 130px;

            p {
                line-height: 1.1;
            }
        }

        &--large {
            height: 330px;
            font-size: 2.25rem;
            font-family: $font-family-gfs-didot;
        }

        &__icon {
            margin: 0 auto 10px;
            position: relative;
            text-align: center;

            &--bg {
                display: inline-block;
                padding: 25px;
                background: #f1f1f1;
                border-radius: 50%;
            }
        }

        &--large &__icon--bg {
            width: 115px;
            height: 115px;

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        a {
            font-weight: 900;
            color: $linkColor;
        }
    }

    @at-root .m-video-card {
        &__bg {
            height: 285px;
            background: $grey-100 no-repeat center / cover scroll;
        }

        &__overlay {
            display: block;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.6);
            color: #fff;
            font-size: 3rem;
            position: relative;

            .fa-stack {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .fa-play {
                    position: relative;
                    left: 4px;
                }
            }
        }

        &__body {
        }
    }
}

.o-simple-text-container {


    @media #{$mobile} {
        padding-left: 10px;
        padding-right: 10px;
    }

    a {
        color: $linkColor;
        font-weight: 900;

        &.a-btn-main {
            color: white;
            font-weight: 900;
            padding: 11px 54px;
        }
    }

    h3, .h3 {
        font-size: 2.1rem;
        margin-top: 32px;

        &:first-child {
            margin-top: 0;
        }

        @media #{$mobile} {
            &.smaller-title {
                font-family: $font-family-avenir;
                font-size: 1.25rem;
                font-weight: 700;
            }
        }
    }

    h2.a-small-title {
        font-size: 2.1rem;
        margin-top: 32px;

        &:first-child {
            margin-top: 0;
        }

        @media #{$mobile} {
            font-family: $font-family-avenir;
            font-size: 1.25rem;
            font-weight: 700;
        }
    }



    ol {
        margin-left: 2.25rem;

        li {
            padding-bottom: 1rem;
        }
    }

    ul {
        margin-left: 2.25rem;

        li {
            padding-bottom: 0.6rem;
        }
    }

    table {
        thead {
            background: $carleDarkBlue;
            color: #fff;
        }
    }
}