﻿@import '../global/_variables.scss';

.events-single {

    .o-event-details {
        margin: 64px auto;

        @include breakpoint(large down) {
            margin: 24px auto;
        }
    }

    label[for=Address2_Value]:after {
        content: ' (Apartment, Unit, Building, etc.)';
        font-weight: normal;
    }

    label[for=Address1_Value]:after {
        content: ' (Street, P.O. Box, Company)';
        font-weight: normal;
    }

    .formwidget-submit-text {
        margin-bottom: 60px;
        box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.08);
        padding: 24px 24px 24px 24px;
        background-color: white;
    }

    form {
        max-width: 350px;
        color: #555559;

        .form-field {
            margin-top: 16px;
        }

        input {
            margin: 0;
        }

        .field-validation-error {
            color: $carleRed;
        }

        input[type='submit'] {
            display: inline-block;
            border: 2px solid transparent;
            background-color: transparent;
            color: white;
            -webkit-transition: background-color .3s ease, color .3s ease;
            -o-transition: background-color .3s ease, color .3s ease;
            transition: background-color .3s ease, color .3s ease;
            margin-top: 24px;
            margin-bottom: 32px;
            text-decoration: none;
            padding: 12px 20px;
            font-size: 14px;
            font-weight: 900;
            border-color: #C32032;
            background-color: #C32032;
            color: white;
        }

        label {
            margin: 20px 0 5px;
            font-weight: $global-weight-bold;
            color: $maintextcolor;
        }

        label[for="Disclaimer_Value"] {
            display: inline-block;
            float: right;
            width: 320px;
            font-weight: normal;
            margin: 0;
        }

        span.ktc-checkbox {
            position: relative;
            top: 5px;
        }

        form button[type=submit] {
            margin-top: 30px;
        }
    }
}

.events {

    input[type=text] {
        border: 1px solid #555559;
        color: #555559;
        height: 50px;
    }

    .shadow-box {
        margin-top: 0;
    }

    .section-header-center {
        margin-bottom: 24px;
    }

    .a-select {
        height: 100px;

        select {
            height: 50px;
        }

        &:after {
            top: 58%;
        }
    }

    .filter-button {
        top: 37px;
        position: relative;
        padding: 12px 20px;

        @include breakpoint(large down) {
            top: 0;
        }
    }
}

.o-event-card-image {
    background-size: cover;

    @include breakpoint(medium down) {
        min-height: 350px;
    }

    @include breakpoint(small down) {
        min-height: 200px;
    }
}

.o-event-card-details {
    position: relative;
    padding: 60px 24px 24px;

    .m-event-header {
        font-family: "Avenir LT Std", sans-serif;
        font-weight: $global-weight-bold;
        margin-bottom: 32px;
    }

    .m-event-location {
        strong {
        }

        &--details {
            margin-left: 5px;

            &:after {
                content: '';
                display: block;
                margin-bottom: 24px;
            }
        }
    }


    .m-event-card_datebox {
        position: absolute;
        left: 24px;
        top: 24px;
        color: $carleRed;
        font-size: 1.5rem;

        .m-event-card_datenumber {
            display: block;
            text-align: center;
            width: 100%;
            color: $maintextcolor;
        }
    }
}
