/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell {
	width: 100%;
	max-width: 1440px;
	padding: 0 30px;
	margin: 0 auto;

	@media #{$mobile} {
		padding: 0 15px;
	}

	&--login {
		max-width: 1132px;
	}

	&--membership {
		max-width: 860px;
	}

	&--chpp {
		max-width: 1276px;
		padding:0;
	}
}
