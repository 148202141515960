﻿#careersResults, #trialsResults {
    margin-top: 80px;

    .search-overview {
        color: $carleRed;
        text-transform: uppercase;
        font-size: 0.875rem;
    }
}

.trial-results-tbl {
    &__head{
        &--name{
            width: 50%;
        }
    }
    &__cell{
        padding: 16px;
    }
    &--loading {
        opacity: 0.6;
    }
}

.for-providers-careers-search {
    .table tbody {
        $adjust: 11%;

        td:nth-child(1) {
            width: 23% + $adjust;
        }

        td:nth-child(2) {
            width: 20.7% - $adjust;
        }
    }
}