// Map

.map {
	display: inline-block;

	.map__inner {
		position: relative;
		width: 465px;
		height: 378px;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	.map__info {
		display: flex;
		padding: 0px 0 0 30%;

		span {
			position: relative;
			display: inline-block;
			font-weight: 900;

			&:before {
				content: '';
				position: relative;
				display: inline-block;
				width: 16px;
				height: 16px;
				margin: 0 15px 0 0;
				background-color: $carleRed;
			}

			&.green {
				&:before{
					background-color: $linkColor;
				}
			}

			& ~ span {
				margin-left: 52px;
			}
		}
	}

	@media #{$mobile} {
		display: block;

		.map__inner {
			position: relative;
			width: 300px;
			height: 300px;
			margin: 0 auto;
		}
	}
}
