﻿.contact-us {
    margin-top: 30px;
   // background-color: $carleLightGrey; //yk
    padding:9px;
}

.o-sidebar {
    border-left: 1px solid $carleMediumGrey;

    p {
        margin-bottom: 10px;
    }
}

// TODO: REMOVE TEMPORARY STYLING AND MARKUP
.static-partial-administration {
    &__image {
        width: 175px;
        height: 166px;
        background: #979797;
        margin-bottom: 20px;
    }

    &__body {
        width: 70%;
    }
}

.mypatient{
    &__login{
        display: block;
        width: 100%;
        color: $linkColor;
        text-align: right;
        font-weight: 900;
    }
}

.collaboration-table {
    border-collapse: unset;
    td {
        vertical-align: top;
        width: 40%;
    }

    tbody tr:nth-child(even) {
        background-color: #f1f1f1;

        td {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
    }

    tbody tr:nth-child(odd) {
        
        
        td {
            padding: 0;
        }
    }
}

// Research Team Text

.o-research-team-text {
    display: flex;
    padding: 0 50px 0 84px;

    h2 {
        position: relative;
        line-height: 1.13;
        padding: 0 0 30px;
        margin: 0 0 30px;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 94px;
            height: 4px;
            background-color: $carleRed;
        }
    }

    .m-research-team-text__head {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 30px 0 0;
    }

    .m-research-team-text__body {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 10px 0 0;
    }

    @media #{$mobile} {
        display: block;
        padding: 0;

        h2 {
            position: relative;
            line-height: 1.13;
            padding: 0 0 15px;
            margin: 0 0 15px;
            font-size: 2rem;
        }

        .m-research-team-text__head {
            max-width: 100%;
            padding: 0px;
        }

        .m-research-team-text__body {
            max-width: 100%;
            padding: 0px;
        }
    }
}

// Researchers Team

.o-researchers-team {
    padding: 0 0 0 80px;

    .grid-x {
        display: flex;
        margin: 0 -24px;
    }

    .cell {
        display: flex;
        padding: 0 24px;
    }

    .a-btn-main {
        position: relative;
        padding: 12px 50px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 400;

        i {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            font-size: 11px;
        }
    }

    .a-researchers-team__title {
        margin: 0 0 25px;
        font-size: 2rem;
        letter-spacing: .035em;
    }

    .m-researchers-team__actions {
        text-align: center;
    }

    @media #{$large-desktop} {
        padding: 0 20px;
    }

    @media #{$small-desktop} {
        .grid-x {
            display: flex;
            margin: 0 -15px;
        }

        .cell {
            display: flex;
            padding: 0 15px;
        }
    }

    @media #{$mobile} {
        padding: 0;

        .grid-x {
            display: block;
            margin: 0;
        }

        .cell {
            padding: 0;
        }
    }
}

// Research Team

.o-research-team {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    padding: 34px 32px;
    margin: 0 0 72px;
    box-shadow: 0 4px 17px 0 rgba(0,0,0,0.1);
    background-color: $white;

    h3 {
        font-family: $font-family-avenir;
        font-weight: 900;
    }

    p {
        margin: 0 0 10px;
        line-height: 1.25;
    }

    .m-research-team__image {
        flex: 0 0 176px;
        max-width: 176px;
        position: relative;
        background-size: cover;
        background-position: center center;
        transition: opacity .3s ease;

        a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &:hover {
            opacity: .7;
        }
    }

    .m-research-team__content {
        flex: 1;
        padding: 11px 10px 17px 27px;
    }

    .m-research-team__actions {
        padding: 29px 0 0;

        a {
            display: block;
            font-weight: 900;
            color: $linkColor;
            transition: color .3s ease;

            span {
                color: $maintextcolor;
            }

            & ~ a {
                margin-top: 10px;
            }

            &:hover {
                text-decoration: none;
                color: $carleRed;
            }
        }
    }

    &--alt {
        padding: 0;
        margin: 0 0 33px;
        box-shadow: none;

        p {
            margin: 0 0 10px;
        }

        .m-research-team__content {
            padding: 8px 50px 17px 36px;
        }

        .m-research-team__actions {
            padding: 2px 0 0;
        }
    }

    @media #{$large-desktop} {
        &--alt {

            .m-research-team__content {
                padding: 8px 0px 17px 36px;
            }
        }
    }

    @media #{$small-desktop} {
        padding: 20px;
        margin: 0 0 30px;

        &--alt {
            padding: 0;
        }
    }

    @media #{$mobile} {
        display: block;

        .m-research-team__image {
            max-width: 100%;
            padding: 0 0 60%;
        }

        .m-research-team__content {
            padding: 20px 0 0;
        }
    }
}

.for-providers-careers {
    .page-header {
        .large-6 {
            padding: 32px 0;
        }

        .large-offset-1 {
            border-left: 1px solid #d8d8d8;
            padding-left: 48px;
            padding-top: 32px;
            padding-bottom: 32px;

            p {
                margin-top: 0;
                margin-bottom: 0;
            }

            .a-btn-main {
                text-align: center;
                height: 48px;
                padding: 13px 0;
                width: 60%;
                margin-bottom: 16px;
                margin-top: 12px;
            }

            .provider-careers-search {
                margin-top: 16px;

                p {
                    margin: 16px 0 8px;
                }
            }

            @include breakpoint(medium down) {
                border-left: 0 none;
                padding-left: 0;

                .a-btn-main {
                    width: 100%;
                }
            }
        }
    }

    .contact-us-container {
        @extend .grid-container-small;
    }
}