@import "_variables.scss";

// Search

.camel-case {
    .search-result_title {
        text-transform: capitalize;
    }

    .search-result_content {
        text-transform: capitalize;
    }
}

.search-results-page {
    .search-results-wrap {
        @include breakpoint(small down) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }
}

.search-results {

    .shadow-box {
        padding: 0;
        margin: 48px auto;

        .shadow-content {
            padding: 48px 24px;

            h3 {
                padding: 0 0 12px 0;
                font-family: "Avenir LT Std", sans-serif;
                font-weight: $global-weight-bold;
            }
        }
    }

    h1 {
        padding: 40px 0 0;
    }

    h3 {
        padding: 0px 0 24px;
    }

    .results-count {
        margin: 32px 0 0;
    }

    .filter-buttons {
        margin: 0 0 12px;

        form {
            display: flex;
        }

        input[type=text] {
            display: none;
        }

        .a-select {
            width: 100%;
        }

        select {
            height: 52px;
            margin-right: 7px;
            flex-shrink: 1;
            position: relative;
            top: 1px;
        }

        .a-btn-main {
            flex-shrink: 1;
            height: 53px;
            margin-left: 10px;
        }

        .search-filter {
            border: 1px solid #555559;
            padding: 4px 16px;
            text-align: center;

            &:hover {
                background-color: $linkColor;
                color: white;
            }

            &.active {
                background-color: $linkColor;
                color: white;
            }
        }
    }

    .search-result {
        padding: 32px 0;

        &_title {
            font-size: 1.2rem;
            margin-bottom: 16px;
        }

        &_content {
            line-height: 1.6;
        }

        &_link {
            margin-top: 16px;
        }
    }
}

.m-search {
    position: relative;

    &:hover, &:active, &:focus {
        .m-search__btn {
            background-color: #002A3A;
        }
    }

    &__field {
        width: 100%;
        height: 48px;
        margin: 0;
        padding: 0 0 0 15px;
        border: 1px solid $maintextcolor;
        font-size: 1rem;
        font-weight: 400;
        color: $maintextcolor;
        border-radius: 0 !important;
    }

    &__btn {
        position: absolute;
        top: 4px;
        right: 3px;
        bottom: 4px;
    }

    &--header {
        min-width: 224px;
        display: inline-block;
    }

    @media #{$mobile} {
        max-width: 100%;

        &--header {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
            display: block;
            position: relative;
            padding: 15px 3px 10px;
            background-color: $white;
        }

        &--header &__btn {
            position: absolute;
            top: 18px;
            right: 5px;
            bottom: 13px;
        }
    }
}

