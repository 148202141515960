﻿.o-featured-news-item {
    background-size: cover;
    background-position: center center;

    .grid-container.m-featured-news-item-content {
        margin: 40px auto;

        .large-5 {
            background-color: white;
            padding: 32px 32px 40px;

            h2.a-featured-news-item-headline {
                line-height: 1.2;
            }

            .a-featured-news-item-text {
                margin-bottom: 32px;
            }
        }
    }
}

.public-relations {
    .o-simple-text-container a.a-btn-main {
        padding: 12px 30px !important;
    }
}

.newsroom {
    .m-card .a-card__actions--button a {
        color: white;
    }

    h2.section-header-center {
        margin: 48px 0 32px;

        @include breakpoint(large down) {
            margin: 24px 0;
        }
    }

    .o-simple-text-container {
        h3 {
            font-size: 2rem;
        }
    }

    .tab-button-wrap {
        margin-bottom: 36px;

        .tab-button {
            margin-right: -5px;

            &:after {
                right: -1px;
                z-index: 100;
            }
        }
    }

    .o-section-cards {
        padding: 48px 0;

        .m-cards {
            margin-bottom: 0;

            .m-card {
                margin-bottom: 0;

                p {
                    margin: 24px 0 48px;
                }

                h3 {
                    font-size: 1.25rem;
                }
            }
        }
    }
}

.a-main-newsroom-btn {
    margin-top:32px;
}

.o-category-list-item {
    border-radius: 4px;
    box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.08);
    padding: 24px 24px 32px 24px;
    background-color: white;
    margin: 80px auto 0px;

    h3 {
        margin-bottom: 15px;
    }

    .a-category-list-item-button {
        width: 22%;
        margin: 10px 1%;
        text-align: center;
        height: 60px;
        padding: 19px 0 16px;

        @include breakpoint(medium down) {
            width: 100%;
            margin: 5px 0;
        }
    }
}

.public-relations {
    .page-header {
        margin-bottom: 24px;
    }

    .o-section-team {
        padding: 24px 0;
    }

    .o-section-cards {
        padding: 24px 0;

        .grid-container {
            max-width: 75rem;
        }
    }
}
