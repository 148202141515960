﻿@import '../global/_variables.scss';

.institute {
    .o-intro-textarea {
        max-height: 400px;
        max-width: unset !important;

        @media #{(max-width: #{$responsive-xs})} {
            padding: 0px 0px 20px;
            font-size: 1.4rem;
            position: relative;
            bottom: -20px;
            left: 10px;

            h2 {
                font-size: 1rem;
                line-height: .9;
            }
        }

        @include breakpoint(large down) {
            a.a-btn-main {
                display: none;
            }
        }

        .hero-copy {
            z-index: 2;
            position: absolute;
            height: 100%;
            padding-top: 3%;
            width: 94%;
            left: 20%;

            @include breakpoint(large down) {
                width: 94%;
                padding: 3%;
                left: 0;
                bottom: 0;
            }

            @include breakpoint(medium down) {
                width: 94%;
                padding: 3%;
                left: 0;
                bottom: 0;
            }
        }
    }

    .grid-container {
        .a-btn-main {
            margin-top: 24px;
        }
    }
}


.main-services-content {
    @include breakpoint(large down) {
        padding-left: 1.250rem;
        padding-right: 1.250rem;
    }
}


.a-featured-item-button_main {
    @include breakpoint(small down) {
        width: 100% !important;
        max-width: 100% !important;
        margin-right: 0 !important;
    }
}

.a-featured-item-button_inverse {
    @include breakpoint(small down) {
        width: 100% !important;
        max-width: 100% !important;
        margin-right: 0 !important;
    }
}

.services {
    .two-columns-default {
        .large-6.cell:last-child {
            @include breakpoint(medium down) {
                display: none;
            }
        }
    }

    .page-header {
        padding-top: 0;
        margin-bottom: 0;

        h2.section-header-center {
            margin-bottom: 0;
        }
    }

    .loadingArea {
        min-height: 200px;
        background: #fff;
        text-align: center;

        p:first-child {
            color: $carleRed;
            padding-top: 30px;
            font-size: 1.5rem;
        }

        p:last-child {
            font-size: 1.5rem;
            font-weight: $global-weight-bold;
        }
    }
}

.service-result li {
    vertical-align: top;
    list-style: none;
    padding: 20px 0px 0px;
    width: 28%;
    margin-right: 5%;
    display: inline-block;

    @include breakpoint(large down) {
        width: 45%;
    }

    @include breakpoint(medium down) {
        width: 100%;
        margin-right: 0;
    }
}

.hero-video-title {
    background-color: #002A3A;
    display: inline;
    line-height: 1.8;
    padding: 8px;
    color: white;
    font-family: $font-family-avenir;
    font-weight: $global-weight-bold;

    @include breakpoint(large down) {
        font-size: 1.5rem;
    }

    @include breakpoint(medium down) {
        font-size: 1rem;
    }
}

.background-video {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.hero-video {
    position: relative; 
    
    i {
        position: absolute;
        left: 14px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 10px;
    }
}

.background-video video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;
    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;
    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.services {
    .section-header-w-subhead {
        margin-top: 0;
    }

    .negate-p p {
        line-height: 1.6;
    }

    .two-columns-default {
        .grid-x {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
    }
}

h2.section-header-center {
    text-align: center;
    margin: 64px 0;

    @include breakpoint(medium down) {
        margin: 32px 0;
    }
}

.o-featured-clinics {
    margin-bottom: 44px;

    @include breakpoint(medium down) {
        margin-bottom: 24px;
    }

    .o-small-shadowbox {
        border-radius: 4px;
        -webkit-box-shadow: 0px 0px 26px 5px rgba(0, 0, 0, 0.08);
        box-shadow: 0px 0px 26px 5px rgba(0, 0, 0, 0.08);
        padding: 16px;

        .clinic-headline-wrap {
            display: table;
            min-height: 58px;
            margin-bottom: 12px;
        }

        .clinic-headline {
            display: table-cell;
            vertical-align: middle;
            font-size: 1.1375em;
        }

        .clinic-description {
            font-size: .8625em;
        }
    }

    .see-more-wrap {
        margin-top: 40px;
    }
}

.institute-research {
    margin: 64px auto;

    .research-item {

        h3 {
            font-family: $font-family-avenir;
            font-weight: 900;
            font-size: 1.25rem;

            a {
                color: $linkColor;

                &:hover {
                    color: $linkHoverColor;
                }
            }
        }

        p {
            @include breakpoint(small down) {
                display: none;

                &.learn-more {
                    display: block;
                }
            }
        }
    }

    .large-shadowbox {
        border-radius: 4px;
        -webkit-box-shadow: 0px 0px 26px 5px rgba(0, 0, 0, 0.08);
        box-shadow: 0px 0px 26px 5px rgba(0, 0, 0, 0.08);
        padding: 24px 32px 32px;
        position: relative;
        margin: 24px 0;

        @include breakpoint(small down) {
            padding-bottom: 24px;
            margin: 12px 0;
        }

        h3 {
            margin-bottom: 24px;

            @include breakpoint(small down) {
                margin-bottom: 0;
            }
        }

        .learn-more {
            position: absolute;
            bottom: 0;
            right: 24px;
        }
    }
}

.o-institute-icons {
    .o-small-shadowbox {
        border-radius: 4px;
        -webkit-box-shadow: 0px 0px 26px 5px rgba(0, 0, 0, 0.08);
        box-shadow: 0px 0px 26px 5px rgba(0, 0, 0, 0.08);
        padding: 32px 16px 16px;
        position: relative;

        p {
            text-align: center;

            &.learn-more {
                position: absolute;
                bottom: 0;
                width: 100%;
                left: 0;
            }
        }
    }
}

.services-single {
    .for-managers-supervisors-eap, .for-employer-purchaser-eap, .for-employees-members-eap {
        .large-8 {
            padding-top: 0 !important;
        }
    }

    .research-giving {
        margin-bottom: -50px;
    }

    .o-sidebar {
        border: 0;
    }

    .a-feature-images img {
        width: 100%;
    }

    .gallery-item {
        padding-right: 16px;
    }

    .mfp-iframe {
        padding: 20px;
        background: white;
    }

    .o-section-awards {
        padding-bottom: 32px;

        p {
            margin-top: 24px;
        }
    }

    ul.list-awards {
        margin-bottom: 0;
    }

    .page-header {
        a.a-btn-inverse {
            min-width: 175px;
            padding: 12px 10px;
            text-align: center;
            margin-bottom: 8px;
        }

        a.a-btn-main {
            min-width: 175px;
            padding: 12px 10px;
            margin-right: 24px;
            text-align: center;
            margin-bottom: 8px;

            @include breakpoint(large down) {
                margin-bottom: 8px;
            }
        }

        .header-description {
            p {
                margin: 0 0 32px;
            }
        }
    }

    &.rural-interior {
        .page-header {
            margin-bottom: 12px;
        }
    }

    .section-header-center {
        margin-top: 40px;
    }

    .main-services-content {
        h3 {
            font-size: 2rem;
        }
    }

    a[target=_blank] {
        &:after {
            content: ' \f08e';
            display: inline-block;
            margin-left: 5px;
            font-family: FontAwesome;
        }
    }

    .o-footer, .o-header, .page-header {
        a[target=_blank] {
            &:after {
                content: '';
                margin-left: 0;
            }
        }
    }

    a.pdf[target=_blank] {
        &:after {
            content: '';
        }
    }

    .related-services-resources {
        .services-see-more {
            &:before {
                content: 'See More ';
                font-weight: $global-weight-bold;
                color: $linkColor;
            }

            &.open {
                &:before {
                    content: 'See Less ';
                    font-weight: $global-weight-bold;
                    color: $linkColor;
                }
            }

            i {
                color: $linkColor;
            }
        }
    }

    .service-list-content {
        .large-4 {
            text-transform: capitalize;
        }

        .conditions-see-more {
            &:before {
                content: 'See More ';
                font-weight: $global-weight-bold;
                color: $linkColor;
            }

            &.open {
                &:before {
                    content: 'See Less ';
                    font-weight: $global-weight-bold;
                    color: $linkColor;
                }
            }

            i {
                color: $linkColor;
            }
        }
    }
}

.service-list-content .a-ul_3columns .cell.large-4 {
    padding-right: 15px !important;
}

.m-card_10 .a-ul_3columns .cell.large-4 {
    padding: 10px 0;
}

.hidden {
    display: none !important;
}

.institute {

    .m-card_10 .cell.large-12 {
        padding: 40px 24px 40px;
    }

    .related-services-resources {
        .services-see-more {
            &:before {
                content: 'See More ';
                font-weight: $global-weight-bold;
                color: $linkColor;
            }

            &.open {
                &:before {
                    content: 'See Less ';
                    font-weight: $global-weight-bold;
                    color: $linkColor;
                }
            }

            i {
                color: $linkColor;
            }
        }
    }

    .service-list-content {
        .large-4 {
            text-transform: capitalize;
        }

        .conditions-see-more {
            &:before {
                content: 'See More ';
                font-weight: $global-weight-bold;
                color: $linkColor;
            }

            &.open {
                &:before {
                    content: 'See Less ';
                    font-weight: $global-weight-bold;
                    color: $linkColor;
                }
            }

            i {
                color: $linkColor;
            }
        }
    }

    .m-featured-news-item-content {
        p {
            margin-bottom: 48px;
        }

        .section-header:after {
            margin-bottom: 30px;
        }
    }

    .page-header a.a-btn-main, .page-header a.a-btn-inverse {
        width: 175px;
        padding: 12px 0;
        text-align: center;

        @include breakpoint(small down) {
            width: 100%;
            margin-bottom: 8px;
        }
    }

    .m-featured-item-content .a-btn-main {
        margin-right: 24px;
    }

    .m-featured-item-content .a-btn-main, .m-featured-item-content .a-btn-inverse {
        padding: 12px 0;
        text-align: center;
        min-width: 175px;
    }

    .o-section-awards {
        padding-bottom: 0;

        ul.list-awards {
            margin-bottom: 16px;
        }
    }
}

    .service-list-content {
        margin-bottom: 64px;

        @include breakpoint(medium down) {
            margin-bottom: 24px;
        }
    }

