﻿@import '../global/_variables.scss';

.public-relations {
    .a-card-team {
        p {
            margin-bottom: 0;
        }
    }
}

.article-single {
    .slick-dots {
        display: block !important;
    }
}

.category-page {
    padding-top: 64px;

    @include breakpoint(large down) {
        padding-top: 64px;
    }

    @include breakpoint(small down) {
        padding-top: 24px;
    }
}

.o-topicsofinterest {
    background: #4f4f4f;

    .m-topicsofinterest-content {
        margin: 20px auto;

        .large-offset-7 {
            background-color: white;
            padding: 30px 20px;
        }

        h2.a-topicsofinterest-headline.section-header {
            &:after {
                margin-top: 24px;
            }
        }
    }
}

body.community {
    .tab-button.community {
        background-color: $linkColor;
        color: white;

        &:after {
            right: 1px;
        }
    }
}

body.culture-of-quality {
    .tab-button.culture-of-quality {
        background-color: $linkColor;
        color: white;

        &:after {
            right: 1px;
        }
    }
}

body.redefining-healthcare {
    .tab-button.redefining-healthcare {
        background-color: $linkColor;
        color: white;

        &:after {
            right: 1px;
        }
    }
}

body.staying-healthy {
    .tab-button.staying-healthy {
        background-color: $linkColor;
        color: white;

        &:after {
            right: 1px;
        }
    }
}

body.all {
    .tab-button.all {
        background-color: $linkColor;
        color: white;
    }
}

/*.carle-slick-slider {

    .slick-list {
        margin-left: -15%;
        width: 130%;
    }
}*/

.slick-slide {
    opacity: 0.5;

    &.full-bright {
        opacity: 1;
    }

    @include breakpoint(small down) {
        max-width: 100%;
        margin-right: 24px;
    }

    &.slick-current {
        opacity: 1.0;
    }
}

.articles-slider {
    h2.section-header-center {
        margin-top: 80px;
        margin-bottom: 40px;

        @include breakpoint(medium down) {
            margin: 20px auto;
        }
    }
}

.newsroom {
    .articles-container .o-article-card {
        padding: 0;

        .m-article-content {
            padding-bottom: 0;
        }
    }
}

.articles-container {
    margin: 0px auto 60px;

    @include breakpoint(medium down) {
        margin: 24px auto;
    }

    .m-articles-container-header {
        text-align: center;
    }

    .m-read-more-btn-wrap {
        margin-top: 48px;
        text-align: center;
    }



    .o-article-card {
        position: relative;
        border-radius: 4px;
        box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.08);
        padding: 0 0 32px 0;
        background-color: white;

        @media #{$mobile} {
            margin-bottom: 1rem;
        }

        .large-5 {
        }

        .m-article-image {
            background-size: cover;
            background-position: center center;
            height: 240px;
            width: 100%;
        }

        .m-article-details {
            margin-top: 15px;
            padding: 0 24px;

            p {
                display: inline-block;
                width: 45%;
                font-weight: $global-weight-bold;
                font-size: 0.75rem;

                &.a-article-category {
                    color: $carleRed;
                    text-transform: uppercase;
                    float: left;
                    font-size: 0.875rem;

                    a {
                        color: $carleRed;
                        text-transform: uppercase;

                        &:hover {
                            color: $carleRed;
                            text-decoration: none;
                        }
                    }
                }

                &.a-article-date {
                    text-align: right;
                    float: right;
                }
            }
        }

        .m-article-content {
            padding: 0 24px 20px;


            .a-article-title {
                margin-bottom: 16px;
                font-size: 1.25rem;
                line-height: 1.2;

                a {
                    color: $linkColor;
                    font-family: 'Avenir LT Std', sans-serif;
                    font-style: normal;
                    font-weight: 900;

                    &:hover {
                        color: $linkHoverColor;
                        text-decoration: none;
                    }
                }
            }

            .a-article-excerpt {
                line-height: 1.4;
                margin-bottom: 24px;
            }
        }

        .a-article-permalink {
            position: absolute;
            bottom: 16px;
            right: 24px;
            font-size: .8625em;
            font-weight: $global-weight-bold;
        }
    }

    .o-article-card-slider {
        position: relative;
        border-radius: 4px;
        box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.08);
        padding: 0px;
        background-color: white;
        margin: 20px;
        left: -14px;




        .m-article-image {
            background-size: cover;
            background-position: center center;
            height: 296px;
            width: 100%;

            @include breakpoint(medium down) {
                height: 170px;
            }
        }

        .m-article-details {
            margin-top: 15px;
            padding: 0 24px;

            p {
                display: inline-block;
                width: 45%;
                font-weight: $global-weight-bold;
                font-size: 0.725em;

                &.a-article-category {
                    color: $carleRed;
                    text-transform: uppercase;
                    float: left;
                }

                &.a-article-date {
                    text-align: right;
                    float: right;
                }
            }
        }

        .m-article-content {
            padding: 0 24px 30px;
            min-height: 120px;


            .a-article-title {
                margin-bottom: 16px;
                font-size: 1.25rem;

                a {
                    color: $linkColor;
                    font-family: 'Avenir LT Std', sans-serif;
                    font-style: normal;
                    font-weight: 900;

                    &:hover {
                        color: $linkHoverColor;
                        text-decoration: none;
                    }
                }
            }

            .a-article-excerpt {
                line-height: 1.4;
                margin-bottom: 24px;
            }
        }



        .a-article-permalink-right {
            position: absolute;
            bottom: 16px;
            right: 24px;
            font-size: .8625em;
            font-weight: $global-weight-bold;
        }
    }
}
