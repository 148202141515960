/* ------------------------------------------------------------ *\
	Footer-new
\* ------------------------------------------------------------ */

.footer-new {
	padding: 32px;
	border-top: 1px solid #979797;
	background-color: #ffffff;

	.footer__logo {
		@media #{$mobile} {
			text-align: center;
		}
	}

	.footer__logo a {
		display: inline-block;
		width: 120px;
		height: 32px;
		background-size: cover;
		background-position: center center;
	}
}
