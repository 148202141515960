// List Links

.list-links {
    margin: 0 0 20px;
    list-style-type: none;
    font-size: 1rem;

    a {
        font-weight: 700;
        transition: color 0.3s ease;

        &:hover {
            color: $carleRed;
            text-decoration: none;
        }
    }

    li {
        & ~ li {
            margin-top: 10px;
        }
    }
}

// List Awards

.list-awards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -16px 82px;
    list-style-type: none;

    li {
        padding: 0 16px;
        margin-bottom: 16px;
    }

    span {
        display: block;
        width: 128px;
        text-align: center;
        background-color: $white;
        box-shadow: 0 4px 17px 0 rgba(85, 85, 89, 0.1);
        text-align: center;
        flex: 0 0 100%;
        max-width: 100%;
        line-height: 128px;

        img {
            display: inline-block;
        }

        &:focus {
            outline: 4px solid $focusColor;
            outline-offset: 4px;
        }
    }

    @media #{$large-desktop} {
        margin: 0 -5px;
    }
}

// List Bullets

.list-bullets {
    & ~ .list-bullets {
        margin-top: 20px;
        margin-bottom: 40px;
    }

    h4 {
        margin: 0 0 23px;
        line-height: 1;
        font-family: $font-family-avenir;
        font-weight: 700;
        font-size: 1rem;
    }

    ul {
        margin: 0;
        padding: 0 0 0 30px;
        letter-spacing: -0.025em;
    }
}

// List Requirements

.list-requirements {
    list-style: none;
    margin: 0;

    li {
        margin-bottom: 20px;
        font-family: 'Avenir LT Std', sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0;
    }

    > li {
        position: relative;
        padding-left: 37px;

        &:before {
            content: '\2022';
            position: absolute;
            left: 12px;
            top: 1px;
        }
    }

    > li > ul {
        list-style: none;
        margin: 0;
        margin-top: 20px;
    }

    > li > ul > li {
        padding-left: 3px;

        &:before {
            content: '-';
            margin-right: 6px;
        }
    }
}
