// Intro Text

.o-intro-text {
    position: relative;
    padding: 112px 50px 192px;
    background-size: cover;
    background-position: center center;

    .o-intro-textarea {
        max-width: 512px;
        display: inline-block;
        color: $white;
        font-weight: 700;
        font-family: $font-family-avenir;
        line-height: 1;
        font-size: 3rem;

        span {
            display: block;
            padding: 8px 5px;
            margin-bottom: 16px;
            background-color: $brand;

            @include breakpoint(small down) {
                margin-bottom: 8px;
            }

            & ~ span {
                display: inline-block;
            }
        }
    }

    .m-intro__video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            object-fit: cover;
        }
    }

    .m-intro-text__entry {
        position: relative;
        z-index: 1;
        padding: 0 0 0 49px;
    }

    @media #{(max-width: #{$responsive-md})} {


        .o-intro-textarea {
            font-size: 2rem;
        }
    }


    @media #{(max-width: #{$responsive-sm})} {
        min-height: 240px;

        .m-intro__video {
            display: none;
        }

        .m-intro-text__entry {
            margin-top: calc(17% - 50px);
            padding: 0;
        }
    }

    @media #{(max-width: #{$responsive-xs})} {
        padding: 52px 0px 20px;

        .o-intro-textarea {
            font-size: 1rem;
            line-height: .9;
        }
    }
}

.home .o-intro-text {

    .o-intro-textarea {
        max-width: 700px;

        span {
            display: inline-block;
        }
    }
}
