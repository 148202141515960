// main

.main {
	padding: 16px 0;

	&--offsets {
		padding: 0;
	}
}



