// Card Search

.m-card-search {
    position: relative;
    margin: 0 0 66px;
    padding: 28px 24px 40px;
    background-color: $white;
    box-shadow: 0 8px 17px 0 rgba(85,85,89,0.1);

    h3 {
        font-size: 2rem;
        letter-spacing: .013em;
        font-family: $font-family-gfs-didot;
        text-align: center;
    }


    .m-card-search-title h2 {
        font-size: 2rem;
        letter-spacing: .013em;
        font-family: $font-family-gfs-didot;
        text-align: center;
    }

    label {
        font-size: 1rem;
        margin-bottom: 5px;
    }

    .m-card-search__body {
        padding: 0 45px 0;

        p {
            margin-bottom: 0.7rem;
            font-size: inherit;
            font-weight: 900;
        }

        .m-search__btn {
            position: absolute;
            top: auto;
            right: 3px;
            bottom: 4px;
            height: 40px;
        }
    }

    .m-card-search__actions {
        padding: 41px 12px 0;
        text-align: center;

        .a-btn-main {
            margin-right: 28px;
            padding: 12px 26px;
            font-size: 14px;
            font-weight: 900;
        }

        .a-btn-inverse {
            padding: 12px 20px;
            font-size: 14px;
            text-transform: uppercase;
        }
    }

    &--pull-up {
        position: relative;
        margin-top: -113px;
    }

    @media #{$large-desktop} {
        .m-card-search__actions {
            .a-btn-main {
                margin-right: 15px;
                padding: 12px 20px;
                font-size: 14px;
            }

            .a-btn-inverse {
                padding: 12px 20px;
                font-size: 14px;
                text-transform: uppercase;
            }
        }
    }

    @media #{$small-desktop} {
        padding: 28px 14px 40px;

        .m-card-search__body {
            padding: 22px 10px 0;
        }
    }

    @media #{$mobile} {
        padding: 28px 14px;
        margin: 0 0 24px;

        .m-card-search-title {
            font-size: 1.5rem;
            text-align: center;

            &.a-title-underline {
                &:after {
                    left: 50%;
                    bottom: -3px;
                    transform: translateX(-50%);
                }
            }
        }

        .m-search {
            display: none;
        }

        .m-card-search__body {
            padding: 0px 0px 0;

            p {
                display: none;
            }
        }

        .m-card-search__actions {
            padding: 17px 23px;

            .a-btn-main {
                display: block;
                margin-bottom: 16px;
                margin-right: 0;
                padding: 8px 20px;
                text-align: center;
            }

            .a-btn-inverse {
                display: block;
                padding: 8px 20px;
                text-align: center;
            }
        }

        &--pull-up {
            position: relative;
            margin-top: 0px;
        }
    }
}

// Card Links

.m-card-links {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    box-shadow: 0 8px 17px 0 rgba(85,85,89,0.1);

    h2, h3 {
        margin: 0 0 45px;
        font-size: 2rem;
        font-weight: 400;
        color: $maintextcolor;

        a {
            font-size: 1.5rem;
        }
    }

    ul {
        display: block;
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
        font-weight: 700;
        list-style-type: none;
    }

    li {
        & ~ li {
            margin-top: 15px;
        }
    }

    .a-card-links__image {
        padding: 0 0 60%;
        background-size: cover;
        background-position: center center;
    }

    .a-btn-inverse {
        padding: 13px 40px;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .m-card-links__actions {
        padding: 15px 40px 0 40px;
    }

    .a-card-links__content {
        padding: 35px 32px;

        .grid-x {
            margin: 0 -4px;
        }

        .cell {
            padding: 0 4px;
        }
    }

    @media #{$mobile} {
        margin: 0 0 20px;

        h2, h3 {
            margin: 0 0 15px;
            font-size: 1.5rem;
        }

        .a-card-links__content {
            padding: 20px 10px;
        }

        li {
            margin-top:0px !important;
            margin-bottom:9px;
        }
    }
}
