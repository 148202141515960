@import '../../Styles/sections/global/variables';
@import '../../Styles/sections/global/mixins';

.google-maps {
    height: 100%;
    &__map {
        width: 100%;
        height: 100%;
        // Popup Overrides
        .gm-style .gm-style-iw-t {
            // Popup Container
            .gm-style-iw-c {
                padding: 24px 16px;
                box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
                border-radius: 0;
            }
            // Popup Arrow
            &:after {
                width: 0;
                height: 0;
                background: none;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-top: 25px solid white;
                box-shadow: none;
                transform: translateX(-50%);
            }
        }

        > .popup {
            display: none;
        }
    }

    .popup {
        &__head {
            @include featureTitle;
        }

        &__body {
            @include featureAddress;
        }

        &__footer {
            a {
                display: inline-block;
                width: 49%;
                font-size: 1rem;
                font-weight: 900;

                + a {
                    text-align: right;
                }
            }
        }
    }
}
