// Contacts

.contacts {
}

// Contact

.contact {
	position: relative;
	margin: 0 0 57px;
	padding-right: 58px;

	h5 {
		text-transform: none;
		color: $linkColor;
	}

	&--border {
		&:after {
			content: '';
			position: absolute;
			top: -9px;
			bottom: -12px;
			right: 56px;
			width: 1px;
			background-color: $carleMediumGrey;
		}
	}

    &--fullheight{
        height: 100%;
    }

	@media #{$mobile} {
		padding-right: 0px;
		margin: 0 0 22px;

		&--border {
			&:after {
				display: none;
			}
		}
	}
}
