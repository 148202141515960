﻿.locations-details {
    .main-content {
        line-height: 24px;

        h2 {
            font-size: 2.5rem;
            margin-top: 24px;
            margin-bottom: 16px;
        }

        ul {
            margin-top: 10px;
            margin-bottom: 10px;

            li {
                margin-bottom: 10px;
            }
        }
    }
}
