﻿.location-single {
    .o-single-location {
        margin-top: pxToResponsiveUnit(64px);
    }

    .m-location {

        > .grid-x {
            @include elevation;
        }

        &, &-app, &-content {
            margin-bottom: pxToResponsiveUnit(48px);
        }

        &-app .a-app {
            background-color: $grey;
        }

        &__result {
            padding: pxToResponsiveUnit(16px) pxToResponsiveUnit(24px);

            .location {
                &-notes {
                    color: $carleDarkBlue;
                    text-transform: uppercase;
                    font-size: 0.875rem;
                    font-weight: $global-weight-bold;
                }

                &-tag {
                    margin-top: pxToResponsiveUnit(16px);
                }

                &-info, &-phone {
                    margin-bottom: 0;
                }
            }

            .result-cta {
                margin-top: pxToResponsiveUnit(40px);
                
                &__directions {
                    text-align: left;

                    a {
                        margin: 0;
                        text-align: center;
                    }
                }

                &__campus {
                    a:before {
                        content: '';
                        @include iconBg(30px, 37px, '/Build/Images/Locations/pdf.png', inline-block);
                        margin-right: 10px;
                        vertical-align: middle;
                    }
                }
            }
        }

        &__image {
            background: transparent no-repeat center / cover scroll;
            width: 416px;

            @media #{$mobile} {
                width: 100%;
                height: 400px;
            }
        }

        &-content {
            h1 {
                margin-bottom: pxToResponsiveUnit(30px);
            }
        }
    }

    .a-ul {
        &--style-none {
            margin-left: 0;

            li {
                padding-left: 0;

                &:after {
                    content: none;
                }
            }
        }
    }

    .o-section-cards {
        .o-grid-card {
            padding: pxToResponsiveUnit(24px) pxToResponsiveUnit(20px) pxToResponsiveUnit(36px) pxToResponsiveUnit(30px);

            h3 {
                margin-bottom: pxToResponsiveUnit(32px);
            }

            .a-ul {
                &--columns-3 {
                    @include breakpoint(small only) {
                        // columns: auto 1;
                        column-width: auto;
                        column-count: 1;
                    }
                }
            }
        }
    }

    .o-footer {
        margin-top: pxToResponsiveUnit(48px);
    }
}

#location-single-body, #location-single-additional {
    font-size: 1rem;
}

.m-covid-alert {
    @extend .query-not-found;
    margin-bottom: 2rem;
}