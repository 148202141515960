﻿@import '../global/_variables.scss';

.conditions {
    .two-columns-default {
        .large-6.cell:last-child {
            @include breakpoint(medium down) {
                display: none;
            }
        }
    }

    .page-header {
        padding-top: 0;
        margin-bottom: 0;

        h2.section-header-center {
            margin-bottom: 0;
        }
    }

    .loadingArea {
        min-height: 200px;
        background: #fff;
        text-align: center;

        p:first-child {
            color: $carleRed;
            padding-top: 30px;
            font-size: 1.5rem;
        }

        p:last-child {
            font-size: 1.5rem;
            font-weight: $global-weight-bold;
        }
    }

    .negate-p p {
        line-height: 1.6;
    }
}


.conditions {
    .two-columns-default {
        .grid-x {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
    }
}

.condition-single {
    .section-header:after {
        margin-bottom: 16px;
    }


    .condition-list-content {
        @include breakpoint(small down) {
            padding: 0 !important;
            border-top: 1px solid #555559;
            border-bottom: 1px solid #555559;

            .m-card_9.grid-container {
                padding: 20px 24px 20px;

                h2 {
                    position: relative;

                    &:after {
                        content: '\f0d7';
                        font-family: FontAwesome;
                        position: absolute;
                        right: 0;
                        top: 12px;
                        font-size: 1rem;
                        color: $linkColor;
                    }

                    &.open {
                        &:after {
                            content: '\f0d8';
                        }
                    }
                }

                .a-ul_3columns {
                    display: none;
                }
            }
        }

        @include breakpoint(medium up) {
            .m-card_9.grid-container {
                .a-ul_3columns {
                    display: block !important;
                }
            }
        }

        .m-card_9.grid-container {
            .a-ul_3columns {
                .large-4 {
                    padding-right: 15px !important;
                }
            }
        }
    }
}

.elsevier-content {
    margin-top: 60px;

    h2, h3, h4, h5 {
        font-size: $h3Size;
    }

    #title {
        display: none;
    }

    #bottom {
        display: none;
    }

    #bottom1 {
        display: none;
    }

    #colorbar {
        display: none;
    }
}

.condition-list-content {
    padding-top: 50px;
    padding-bottom: 50px;

    @include breakpoint(medium down) {
        padding: 24px 0.625rem;
    }

    &.condition-tier-2 {
        background-color: #fafafa;
        font-family: inherit;
    }
}

.condition-page {
    &.page-header {
        margin-bottom: 0;
    }
}

.related-services-resources {
    margin: 60px auto;

    @include breakpoint(medium down) {
        margin: 24px auto;
    }
}
