﻿#fap-search {
    margin-bottom: 56px;

    .provider-search {
        @include elevation;
        padding: 24px 16px;

        label {
            margin-bottom: 8px;
            font-weight: 900;
            font-size: 1rem;
        }

        &__input {

            .m-icon-field {

                &__icon {
                    left: 16px;
                }
            }

            select {
                padding: 0 48px;

                &:after {
                    right: 26px;
                }
            }

            &--name, &--specialty {
                .m-icon-field {
                    padding: 0;

                    input {
                        padding: 16px 48px;
                    }
                }
            }
        }

        &__submit button {
            margin: 0;
            padding: 15px;
        }

        &__tags {
            margin: 0;

            .search-tag {
                display: inline-block;
                height: pxToResponsiveUnit(20px);
                padding-left: pxToResponsiveUnit(12px);
                padding-right: pxToResponsiveUnit(12px);
                font-size: pxToResponsiveUnit(14px);
                margin-bottom: 1rem;
                margin-right: 0.5rem;

                @include tag(#900000);

                button {
                    color: inherit;
                    cursor: pointer;
                }
            }
        }
    }

    .autocomplete-result {
        &:focus, &:active, &[aria-selected="true"] {
            background-color: rgba(0, 0, 0, 0.06);
        }

        &-list {
            z-index: 1001 !important;
        }

        &--match {
            color: $linkColor;
            font-weight: 900;
        }
    }
}

.fap-search {
    .a-app{
        @extend .a-app--bg;
    }
}

.fap-search, .fap-results {
    .heading {
        padding: 48px 0;
    }
}
