@import '_variables.scss';

// Header

.o-header {
    .m-header {
        &-bar {
        }

        &-inner {
            .location-links {
                &__link {
                    position: relative;

                    .a-link-panel {
                        @include elevation;
                        display: none;
                        width: 200%;
                        position: absolute;
                        z-index: 5;

                        @media (max-width: 1024px) {
                            width: 100%;
                        }

                        &__inner {
                            position: relative;
                            padding: 15px;
                            height: 600px;
                            overflow-y: scroll;
                        }

                        &__close {
                            i {
                                color: #555559;
                                font-size: 1.3rem;
                                font-weight: 400;
                            }
                        }
                    }

                    &--find-nearest-location {
                        .a-link-panel {
                            min-width: 290px;
                            right: 0;
                            width: 100%;

                            @media (max-width: 1380px) {
                                left: 0;
                                right: initial;
                                width: 100%;
                            }

                            &__inner {
                                height: auto;
                            }

                            form {
                                margin-top: 10px;
                                padding-top: 10px;
                                border-top: 1px solid $maintextcolor;

                                div {
                                    position: relative;

                                    input {
                                        height: 35px;
                                        padding-right: 60px;
                                    }

                                    button {
                                        font-size: 14px;
                                        padding: 8px 14px;
                                    }
                                }
                            }
                        }
                    }

                    &--shortest-wait {
                        .address {
                            margin-top: 20px;
                            margin-bottom: 20px;
                        }

                        .a-btn-inverse {
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

.m-popover{
    .a-open-panel{
        color: $linkColor;
        cursor: pointer;
        font-weight:700;
        &:hover{
            color:$carleRed;
        }
    }
}

.o-header-chpp {
    .m-header-chpp-bar {
        padding: 4px 23px;
        background-color: #002A3A;
    }

    .m-header-chpp-inner {
        display: flex;
        justify-content: space-between;
        padding: 19px 23px 19px 34px;

        @media (max-width: 1024px) {
            padding: 0;
            display: block;
        }
    }

    .m-header-chpp-entry {
        display: flex;
        align-items: center;
        position: relative;

        @media (max-width: 1024px) {
            padding: 8px 8px 4px;
        }
    }

    .m-header-chpp-entry p {
        margin-bottom: 0;
        padding-left: 15px;
        margin-left: 16px;
        position: relative;
        font-size: 24px;
        font-weight: 700;

        @media #{$large-desktop} {
            font-size: 19px;
        }

        @media (max-width: 1024px) {
            max-width: 192px;
            padding-left: 8px;
            margin-left: 4px;
            line-height: 1.07;
            font-size: 14px !important;
        }

        &:before {
            content: '';
            position: absolute;
            display: inline-block;
            left: 0;
            top: 0;
            width: 1px;
            height: 48px;
            background-color: #555559;

            @media (max-width: 1024px) {
                height: 31px;
            }
        }
    }

    .m-header-chpp-content {
        display: flex;
        align-items: flex-start;
        padding-top: 7px;
    }

    .grid-x {
        justify-content: space-between;
    }

    .a-btn-inverse {
        padding: 0.5rem 1.875rem;
        font-size: 14px;
        font-weight: 400;

        @media #{$large-desktop} {
            padding: 0.5rem 1rem;
        }

        @media (max-width: 1024px) {
            display: none;
            margin: 20px 16px 16px;
            width: calc(100% - 32px);
            text-align: center;
        }
    }

    .a-btn-main {
        margin-left: 35px;
        padding: 0.5rem 2.875rem;
        font-size: 14px !important;
        text-transform: none;
        font-weight: 400;

        @media #{$large-desktop} {
            margin-left: 14px;
            padding: 0.5rem 1.5rem;
        }

        @media (max-width: 1024px) {
            display: none;
            margin: 20px 16px 16px;
            width: calc(100% - 32px);
            text-align: center;
        }
    }

    .search--header {
        margin-left: 35px;

        @media #{$large-desktop} {
            margin-left: 14px;
        }

        @media (max-width: 1024px) {
            display: none;
            padding: 0 16px;
            margin-left: 0px;
        }

        form {
            position: relative;
        }

        .a-search__btn {
            position: absolute;
            right: 5px;
            top: 5px;
        }

        .btn--green {
            padding: 6px 14px;
        }
    }

    .search--header h2 {
        display: none;

        @media (max-width: 1024px) {
            display: block;
            margin-bottom: 20px;
            text-align: center;
            font-size: 24px;
            font-family: $font-family-gfs-didot;
        }
    }

    .m-header-chpp-nav-holder {
        border: 1px solid #555559;
        border-left: 0;
        border-right: 0;

        @media (min-width: 1025px) {
            display: block !important;
        }

        @media (max-width: 1024px) {
            display: none;
            padding-top: 30px;
        }
    }

    .js-clone {
        display: none;

        @media (max-width: 1024px) {
            display: block;
        }
    }

    .m-header-chpp-entry .nav-trigger {
        position: absolute;
        right: 20px;
        top: 15px;
    }
}
